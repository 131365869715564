import { Link } from "react-router-dom";
import { ButtonKnowMore } from "../../buttons";
import "./focusAreaCard.css";

const FocusAreaCard = ({
  title,
  image,
  description,
  wlink,
  link,
  height,
  name,
  leaders,
  textArray,
  onlyTitle
}) => {
  return (
    <div className="card-landscape focus-area-card" style={{ height: height }}>
      <div className="focus-area-sub-cont1">
        <img className="focus-area-img" src={image} alt="" />
      </div>
      <div className="focus-area-sub-cont2">
        <div className={leaders ? "fac-sd1" : "fac-sd1 width60p"}>

          {leaders && !onlyTitle ? (
            <div style={{ marginBottom: "0.7rem" }}>
              <h4 className="card-sub-title">{name}</h4>
              <h5 className="card-sub-title-sub">{title}</h5>
            </div>
          ) : (
            <h4 className="card-sub-title">{title}</h4>
          )}
          {
            onlyTitle && <h2 className="card-sub-title card-sub-title-big" >{name}</h2>
          }

          {!textArray ? (
            <p className="p-text">{description}</p>
          ) : (
            <ul>
              {textArray.map((item) => {
                return (
                  <li className="p-text" style={{ margin: "0.7rem 0" }}>
                    {item}
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        {link || wlink
          ? !leaders && (
              <div className="fac-sd2">
                {link && <Link style={{textDecoration:'none'}} to={link} > <ButtonKnowMore/> </Link> }
                {wlink &&  <a style={{textDecoration:'none'}} target="_blank" rel="noreferrer" href={wlink}> <ButtonKnowMore/> </a> }
              </div>
            )
          : null}
      </div>
    </div>
  );
};

export default FocusAreaCard;
