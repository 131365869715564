import "./awardCarousel.css";
import { Carousel } from "react-responsive-carousel";
// eslint-disable-next-line no-unused-vars
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";

const AwardCarousel = ({ dataWeb, dataMob }) => {
  console.log(window.innerWidth);

  return (
    <Carousel infiniteLoop={true} renderThumbs={() => null} styles>
      {window.innerWidth < 400
        ? dataMob.map((item) => {
            return (
              <>
                <div className="car-img-cont">
                  <div className="in-cont">
                    <video className="car-video" controls>
                      <source src={item.image} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </>
            );
          })
        : dataWeb.map((item) => {
            return (
              <div className="car-img-cont">
                <div className="in-cont">
                <video className="car-video" controls>
                  <source src={item.image} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                </div>
                <div className="in-cont">
                <video className="car-video" controls>
                  <source src={item.image2} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                </div>
              </div>
            );
          })}
    </Carousel>
  );
};

export default AwardCarousel;
