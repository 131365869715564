import "./testimonialCard.css";
import {useState} from 'react'

const TestimonialCard = ({ image, name, grad, wd, ctc, descShort,  desc, link }) => {
  const [readMore, setReadMore] = useState(false)

  return (
    <div className="card">
      <div className="t-card-p1">
        <img className="avatar-img" src={image} alt="" />
        <div className="t-card-p1-sub1">
          <h5>{name}</h5>
          <p>Graduated: {grad}</p>
          <p>Work Duration: {wd} </p>
          <p>CTC: {ctc}</p>
        </div>
      </div>
      
      <div className={`t-card-p2 ${readMore ? 'extended' : 'compressed'}`}>
        <div style={{margin:'1rem'}}>

        
        <i class="fas fa-quote-left"></i>
        <div className="t-card-p2-sub1" >
        {readMore ? (<p className="desc" >{desc}</p>) : (<p className="descShort" >{descShort}</p>)}
        <p className="read-more" onClick={() => setReadMore(!readMore)} >{readMore ? 'Read Less' : 'Read More'}</p>
        </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
