import "./feedback.css";
import { StudentFeedback, EmployeeFeedback } from "../../../Components/Feeback";
import { useState } from "react";
import { OrangeButtonLarge } from "../../../utils/buttons/Buttons";

const FeedbackPage = () => {
  const [active, setActive] = useState("student");
  //student or employee
  return (
    <div className="one-view">
      <div className="page-title">
        <h1>Feedback</h1>
      </div>
      <div className="buttons-cont">
        <OrangeButtonLarge text={"Student"} clickFunc={() => setActive('student')} />
        <OrangeButtonLarge text={"Employee"} clickFunc={() => setActive('employee')} />
      </div>
      <div className='feedback-form-title' >
          <h1>{active === 'student' ? 'Student Feedback Form' : 'Employee Feedback Form'}</h1>
      </div>
      <div>
          {
              active === 'student' ? (<StudentFeedback  />) : (<EmployeeFeedback/>)
          }
      </div>
    </div>
  );
};

export default FeedbackPage;
