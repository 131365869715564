import "./Home.css";
import FocusAreaSection from "../../Components/Home Page/Focus Area Section/FocusAreaSection";
import FeaturedCourses from "../../Components/Home Page/Featured Courses/FeaturedCourses";
import Clients from "../../Components/Home Page/ClientsAndPartners/Clients";
import { OurSuccess, KeySectors } from "../../Components/Home Page";
import {keysector_icons, featuredCourses} from './data';
import {
  CarouselA
} from "./../../D-Components/Carousel/Carousel";

import { homePageClientsAndPartners } from "./data"; 

const Home = () => {
  return (
    <>
     <CarouselA />
     {/*
      <div className="home home-data-cont bg-home">
        <div className="home-data-subcont-right" >
        <div className="inner-cont" >
        <h2 className="hero-title-1" >We are</h2>
        <h1 className="hero-title-2" >India's Leading Skilling & Staffing Company</h1>
        </div>
        </div>
      </div>
      */}
      <FocusAreaSection />
      <FeaturedCourses id={3} title={'Featured Courses'} data={featuredCourses} />
      <Clients logos={homePageClientsAndPartners} title={'Our Clients & Partners'}  />
      <OurSuccess/>
      
      <KeySectors dataIcons={keysector_icons} />
      {/* <PlayStoreOrange/> */}
    </>
  );
};
export default Home;
