import "./companyProfile.css";
import WideTextCard from "../../../utils/Cards/Wide Text Card/Wide Text Card";

const cardsData = [
  {
    title: "Vision",
    text: "To be the most preferred and sought after provider of training and development services for business, softskills and placement.",
  },
  {
    title: "Mission",
    text: "To ensure empowerment and sustainable livelihood through holistic skilling, guidance and counselling; to cater to corporates through end to end recruitment and staffing solutions.",
  },
];

const CompanyProfile = () => {
  return (
    <>
      <div className="section-container">
        <div className="section-title-cont">
          <hr className="section-title-line" />
          <h3 className="section-title">Company Profile</h3>
        </div>
        <div className="card-holder company-text-box ">
          <p className="p-text comp-text ">
            In 2006, Orion's journey started with ITES/BPO industry skill
            development whose runaway success led us to successfully skill
            workforce for diverse industries including healthcare, logistics,
            retail, apparel, hospitality among others. With an incredibly high
            placement record across all sectors, Orion made its next successful
            foray into CSR skilling partnering with some of the largest
            corporates in the country.
          </p>
          <p className="p-text comp-text ">
            Over time, working closely with corporates for skilling, Orion saw a
            huge need for a skilled flexible workforce and ventured into it.
            Orion's success has continued in the staffing vertical too providing
            efficient resources minus the hassle of training time and cost for
            partner corporates, creating a win-win equation.
          </p>

          <p className="p-text comp-text ">
            With Orion's rapid growth and extensive geographical spread, we
            identified an urgent need to transition from traditional classroom
            training to technology-enabled, cohort-based live online training as
            well as self-paced on-demand learning for constant education and
            growth of our past and present students, and staffing payroll. This
            led to the digital transformation and growth of Orion to train and
            upskill them across core and soft skills. At Orion, we are charging
            towards forging the next generation of nation-builders for India.
          </p>
        </div>
      </div>
      <div className="card-holder cont-sub-com-prof">
        {cardsData.map((item) => {
          return <WideTextCard title={item.title} text={item.text} />;
        })}
      </div>
    </>
  );
};

export default CompanyProfile;
