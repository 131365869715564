import "./awardsCard.css";

const AwardsCard = ({ name, post, image, text, width, leadership }) => {
  return (
    <div className="awards-card card-landscape"  >
      <div className="award-img" >
      <img src={image} alt="" />
      </div>

      <div className="name-post" >
        <h5 className="name-post-h5" >{name}</h5>
        <p className="name-post-p" >{post}</p>
      </div>

      <p className="awards-card-desc">{text}</p>
    </div>
  );
};
export default AwardsCard;
