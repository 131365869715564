import './aboutUs.css'

const CompanyProfile = () => {
  return (
    <div className="one-view">
      <div className="page-title" ><h1>Company Profile</h1></div>
      <p className="one-view-text-box">
        In 2006, Orion's journey started with ITES/BPO industry skill
        development whose runaway success led us to successfully skill workforce
        for diverse industries including healthcare, logistics, retail, apparel,
        hospitality among others. With an incredibly high placement record
        across all sectors, Orion made its next successful foray into CSR
        skilling partnering with some of the largest corporates in the country.{" "}
      </p>
      <p className="one-view-text-box">
        Over time, working closely with corporates for skilling, Orion saw a
        huge need for a skilled flexible workforce and ventured into it. Orion's
        success has continued in the staffing vertical too providing efficient
        resources minus the hassle of training time and cost for partner
        corporates, creating a win-win equation.{" "}
      </p>
      <p className="one-view-text-box">
        With Orion's rapid growth and extensive geographical spread, we
        identified an urgent need to transition from traditional classroom
        training to technology-enabled, cohort-based live online training as
        well as self-paced on-demand learning for constant education and growth
        of our past and present students, and staffing payroll. This led to the
        digital transformation and growth of Orion to train and upskill them
        across core and soft skills.{" "}
      </p>
      <p className="one-view-text-box">
        At Orion, we are charging towards forging the next generation of
        nation-builders for India.
      </p>
      <div className="mission-vision-box" >
      <p className="one-view-text-box">
        <span className="sub-title"  >MISSION:</span>To ensure empowerment and sustainable livelihood
        through holistic skilling, guidance and counselling; to cater to
        corporates through end to end recruitment and staffing solutions.
      </p>
      <p className="one-view-text-box">
        <span className="sub-title" >VISION:</span>To be the most preferred and sought after provider of training and development services for business, softskills and placement.
      </p>
    </div>
    </div>
  );
};

export default CompanyProfile;
