

export const leadersData = [
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640440960/orion-new-website/main%20home/Sanjeev_nsesyj.png",
    name: "Mr. Sanjeev Kothari",
    title: "Co-founder & Director",
    text: "Industry veteran with 19+ years experience, Sanjeev co-founded Orion to provide meaningful employment to India’s youth. He has been instrumental in creating staffing, technology and content verticals at Orion.",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640668814/orion-new-website/Manish_un0ad4.png",
    name: "Mr. Manish Agarwal",
    title: "Co-founder & Director",
    text: "Manish co-founded Orion with a vision to bridge demand-supply gap in India’s skilled workforce. With over 19 years of deep expertise, he set up & directs processes, governance, finance as well as CSR at Orion. ",
  },
];

export const teamData = [
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641189485/orion-new-website/about_us_new_images/staffs/1_Somnath_Banerjee_mtutxm.jpg",
    name: "Mr. Somnath Banerjee",
    title: "CEO & Co-founder (Orion Corporate Alliance)", /* Director (Corporate Alliance)*/
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640877679/orion-new-website/about_us_new_images/staffs/2_Nitin_Agarwal_jag2lw.jpg",
    name: "Mr. Nitin Agarwal",
    title: "Director (Operations)",
  },

  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640877733/orion-new-website/about_us_new_images/staffs/3_Deepraj_Paul_gyqj5j.jpg",
    name: "Mr. Deepraj Paul",
    title: "Director (Operations & Contentgrill)",
  },

  /*
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640877803/orion-new-website/about_us_new_images/staffs/4_Pran_Krishna_Das_xgqirc.jpg",
    name: "Pran Krishna Das",
    title: "Director (Govt. Business)",
  },
  */
  /*
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641189485/orion-new-website/about_us_new_images/staffs/9_Niladri_ggm4g4.jpg",
    name: "Niladri Banerjee",
    title: "Associate Director (Operations - CSR, School Projects)",
  },
  */
  /*
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641189485/orion-new-website/about_us_new_images/staffs/5_Priyadeep_Sinha_v1g4h5.jpg",
    name: "Priyadeep Sinha",
    title: "Chief Product Officer",
  },
*/

  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640877930/orion-new-website/about_us_new_images/staffs/6_Rakesh_Jha_qb1zpn.jpg",
    name: "Mr. Rakesh Kumar Jha",
    title: "Vice President (Finance & HR)",
  },
  /*
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640878179/orion-new-website/about_us_new_images/staffs/10_Siddhartha_Dey_vlgexv.jpg",
    name: "Siddhartha Dey",
    title: "Vice President (Operations)",
  },
  */
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640877995/orion-new-website/about_us_new_images/staffs/7_Semal_Kumar_Yadav_hb1yom.jpg",
    name: "Mr. Semal Kumar Yadav",
    title: "Vice President (Finance – Projects)",
  },

  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640878060/orion-new-website/about_us_new_images/staffs/8_Santu_Kumar_Ghosh_giwbue.jpg",
    name: "Mr. Santu Kumar Ghosh",
    title: "Vice President (Projects)",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640878234/orion-new-website/about_us_new_images/staffs/11_Soumya_p7vjqa.jpg",
    name: "Mr. Soumya Brata Dhar",
    title: "Vice President (Placements)",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1643712159/lms-app/new-lms-website/12_Nilayan_ubpfrk.jpg",
    name: "Mr. Nilayan Chakraborty",
    title: "Vice President (Operations)",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640878979/orion-new-website/about_us_new_images/staffs/22_Pritam_prkgjj.jpg",
    name: "Mr. Pritam Mukherjee",
    title: "Vice President (Partnership & Alliances)",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640878493/orion-new-website/about_us_new_images/staffs/15_Somnath_frm6ge.jpg",
    name: "Mr. Somnath Acharya",
    title: "Vice President (Operations)",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640879160/orion-new-website/about_us_new_images/staffs/25_Debprasad_ntjkmr.jpg",
    name: "Mr. Debprasad Saha",
    title: "Vice President (Govt. Projects)",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640878661/orion-new-website/about_us_new_images/staffs/17_Manjari_fywkzx.jpg",
    name: "Ms. Manjari Singh",
    title: "Vice President (Study Abroad)",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641560067/orion-new-website/about_us_images/WhatsApp_Image_2022-01-07_at_4.38.34_PM_gb8tbc.jpg",
    name: "Mr. Rohit Venaik",
    title: "Assistant Vice President (Business Dev)",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640878595/orion-new-website/about_us_new_images/staffs/16_Ruptan_cfww06.jpg",
    name: "Mr. Ruptan Ghosh",
    title: "Assistant Vice President (Accounts)",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640878872/orion-new-website/about_us_new_images/staffs/20_Debtirtha_hufl7m.jpg",
    name: "Mr. Debtirtha Chakraborty",
    title: "Assistant Vice President (Corporate Alliance)",
  },
  {
    image:
      "https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/sarbajit_kundu_pic.jpg",
    name: "Mr. Sarbajit Kundu",
    title: "Assistant Vice President (Operations)",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640878926/orion-new-website/about_us_new_images/staffs/21_Arjit_wdy8gq.jpg",
    name: "Mr. Arijit Majumder",
    title: "Assistant Vice President (Human Resources)",
  },
  /*
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641189485/orion-new-website/about_us_new_images/staffs/18_Mehak_grkf8a.jpg",
    name: "Mehak Sarraf",
    title: "General Manager (Orion Infinity HR Solutions)",
  },
  */
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1650969072/orion-new-website/about_us_images/Supriyo_Dutta_pnqugh.jpg",
    name: "Mr. Supriyo Dutta",
    title: "General Manager (Operations)",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1650969068/orion-new-website/about_us_images/Abhijit_Kundu_kx25sn.jpg",
    name: "Mr. Abhijit Kundu",
    title: "General Manager (Operations)",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1650969055/orion-new-website/about_us_images/Dhritiman_Bhattacharya_zw4eak.jpg",
    name: "Mr. Dhritiman Bhattacharya",
    title: "General Manager (Placement)",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640879100/orion-new-website/about_us_new_images/staffs/24_Nabanita_ilp1cn.jpg",
    name: "Ms. Nabanita Banerjee",
    title: "General Manager (Training)",
  },
  
  {
    image:
      "https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/dipambita_sarkar_pic.jpg",
    name: "Ms. Dipambita  Sarkar",
    title: "General Manager (Corporate Alliance)",
  },
  {
    image:
      "https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/sunil_todowal_pic.jpg",
    name: "Mr. Sunil Todowal",
    title: "General Manager (Operations)",
  },
  {
    image:
      "https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/sarnajit_mukherjee_pic.jpeg",
    name: "Mr. Sarnajit Mukherjee",
    title: "General Manager (Operations)",
  },
  /*
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640878803/orion-new-website/about_us_new_images/staffs/19_Prema_wusf0z.jpg",
    name: "Prerna Joshi",
    title: "General Manager (TITP - Japan)",
  },
  */
  
  /*
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641560068/orion-new-website/about_us_images/WhatsApp_Image_2022-01-07_at_4.35.26_PM_jwcg6w.jpg",
    name: "Raj Kothari",
    title: "Vice President (Business Dev)",
  },
  */
  // {
  //   image:
  //     "https://res.cloudinary.com/learnhat/image/upload/v1640878365/orion-new-website/about_us_new_images/staffs/13_Sarbajit_h38qvb.jpg",
  //   name: "Sarbajit Kundu",
  //   title: "Vice President (Operations)",
  // },

  /*
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640878433/orion-new-website/about_us_new_images/staffs/14_Koushik_ztsqys.jpg",
    name: "Koushik Bhattacharyya",
    title: "Asst Vice President (Corporate Alliance)",
  },
  */
  
  /*
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640879036/orion-new-website/about_us_new_images/staffs/23_Rajib_k53fgm.jpg",
    name: "Rajib Singha",
    title: "Asst. Vice President (CSR - Operations)",
  },
  */
];



export const dataAwardsNew = [
  {
    id: "1",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641193558/orion-new-website/awards_and_recognition/1_4_qn4yzt.jpg",
    text:
      "National Entrepreneurship Award 2017 awarded to Orion Edutech in recognition of our initiative in Skill Development by (late) Shri Arun Jaitley (then Finance Minister of India) and Shri Dharmendra Pradhan (Minister of Skill Development & Entrepreneurship, Govt. of India)",
  },

  {
    id: "2",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641193744/orion-new-website/awards_and_recognition/2_4_xst3gp.jpg",
    text:
      "Best Vocational Training Company Award 2017 received from hon'ble MP Dr. Udit Raj & Shri S C Agarwal (Member of Managing Committee) by ASSOCHAM",
  },

  {
    id: "3",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641193829/orion-new-website/awards_and_recognition/3_5_gyuk67.jpg",
    text:
      "Best Vocational Training Institute Award 2017 received from Shri Rajiv Pratap Rudy (then Minister of Skill Development & Entrepreneurship, Govt. of India) by ASSOCHAM",
  },

  {
    id: "4",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641193904/orion-new-website/awards_and_recognition/4_4_e40ymb.jpg",
    text:
      "Ministry of Rural Development (MoRD) certified Orion Edutech as Champion Employer for 2017",
  },

  {
    id: "5",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641194030/orion-new-website/awards_and_recognition/5_1_gnr70w.jpg",
    text:
      "Best Skill Development Training Provider Award received at the Indo-Global Education Summit & Expo (2017)",
  },

  {
    id: "6",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641194139/orion-new-website/awards_and_recognition/6_2_gn9wdc.jpg",
    text:
      "Best Institute - Innovation Award received at Indo-Global Education Summit & Expo (2017)",
  },

  {
    id: "7",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641194364/orion-new-website/awards_and_recognition/7_1_pzefc6.jpg",
    text:
      "Orion Edutech awarded as the Best Skill Development Training Company by Econs (2017)",
  },

  // {
  //   id: "8",
  //   image:
  //     "https://res.cloudinary.com/learnhat/image/upload/v1641194446/orion-new-website/awards_and_recognition/8_2_j8jju7.jpg",
  //   text:
  //     "‘CRISIL MSE * 2’ performance and credit rating by NSIC-CRISIL indicating high credit worthiness received by Orion Edutech",
  // },

  {
    id: "9",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641194521/orion-new-website/awards_and_recognition/9_2_krehh2.jpg",
    text:
      "MSME Excellence Award for Best Practices in Service Sector received from BCC&I (2016)",
  },

  {
    id: "10",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641195015/orion-new-website/awards_and_recognition/10_2_pmlhw1.jpg",
    text:
      "Silver Trophy awarded from Mr. Bandaru Dattatreya (then Minister of State for Labour And Employment, Govt. of India) by ASSOCHAM",
  },

  {
    id: "11",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641195161/orion-new-website/awards_and_recognition/11_2_bourt8.jpg",
    text:
      "Skill Champion of India Award received from Mr. Pallam Raju (then HRD Minister, Govt. of India) by FICCI (2013)",
  },

  {
    id: "12",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641195219/orion-new-website/awards_and_recognition/12_oggq41.jpg",
    text:
      "Best Vocational Training Institute Award received from Indian Education Congress in 2011",
  },

  {
    id: "13",
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641195276/orion-new-website/awards_and_recognition/13_1_kjsaf9.jpg",
    text:
      "Franchisor of the Year Award received from Shri Pradeep Jain (then Minister of State for Rural Development, Govt. of India) by Franchise Plus",
  },
];
