import "./wideTextCard.css";

const WideTextCard = ({ title, text, name, number, email }) => {
  return !name ? (
    <div className="card-landscape wide-text-card">
      <h5 className="card-sub-title wc-title">{title}</h5>
      <p className="p-text">{text} </p>
    </div>
  ) : (
    <div className="card-landscape wide-text-card" style={{textAlign:'center'}} >
      <h5 className="card-sub-title wc-title">{name}</h5>
      <h5 className="p-text ">{number} </h5>
      <h5 className="p-text ">{email} </h5>
    </div>
  );
};

export default WideTextCard;
