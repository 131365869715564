import "./keySectors.css";



const KeySectors = ({dataIcons}) => {
  return (
    <div className="section-container">
      <div className="section-title-cont">
        <hr className="section-title-line" />
        <h3 className="section-title">Key Sectors</h3>
      </div>

      <BlueIconsSection data={dataIcons}/>
    </div>
  );
};

export default KeySectors;

export const BlueIconsSection = ({data}) => {
  return(
    <div className="card-holder ">
        {data.map((item) => {
          return (
            <div className="sub-cont-key-sec-blue-ic">
              <img className="item-blue-ic" src={item.icon ? item.icon : item.link} alt="" />
              <h5 className="text-blue-ic" >{item.name}</h5>
            </div>
          );
        })}
      </div>
  )
}
