import "./about.css";

const About = ({ title, data }) => {
  return (
    <>
      <div className="section-container">
        <div className="section-title-cont">
          <hr className="section-title-line" />
          <h3 className="section-title ">{title}</h3>
        </div>
        <div className="card-holder company-text-box ">
          <p className="p-text comp-text ">
            ContentGrill, through its deep expertise built over years of
            research & development, is Orion Edutech’s content development
            division. With more than 400 subject matter experts and domain
            specialists, ContentGrill develops the most dynamic and interactive
            learning content in diverse languages, structures and formats. With
            hundreds of projects and collaborations with India’s Sector Skills
            Councils as well as organizations, corporates and non-profits across
            beauty & wellness, agriculture, manufacturing, electronics, telecom,
            life sciences, automotive, business & entrepreneurship, mining, food
            processing among sectors gives our team the best perspective to
            develop content to suit your needs and requirements.
          </p>
        </div>
        <div className="card-holder" style={{marginTop:'2rem'}}>
          {data.map((item) => {
            return (
              <div className="card-landscape card-ls-abt-us">
                <div className="cont-dev-card-img-cont">
                  <img src={item.image} alt="" />
                </div>

                <div className='cont-dev-card-sub-cont' >
                  <h5 className="card-sub-title wc-title">{item.title}</h5>
                  <ul className="p-text">
                    {item.dataArray.map((itemin) => {
                      return (
                        <>
                          <li>{itemin}</li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default About;
