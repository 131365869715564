import { ButtonKnowMoreHero } from "../../Components/Buttons/Buttons";
import "../../Pages/Home/Home.css"; 
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export const CarouselA = () => {
  return (
    <>
      <Carousel autoPlay infiniteLoop>
       <SubCompCarousel bgImg={'bg-home'} />
       {/*<SubCompCarouselOpposite bgImg={'bg-home2'} />*/}
       <SubCompCarousel1 bgImg={'bg-home-1'} />
       <SubCompCarousel2 bgImg={'bg-home-2'} />
       <SubCompCarousel3 bgImg={'bg-home-3'} />
       <SubCompCarousel4 bgImg={'bg-home-4'} />
      </Carousel>
    </>
  );
};

export const SubCompCarousel = ({ bgImg }) => {
  return (
    <div id="1" className={`home home-data-cont bg-home ${bgImg}`}>
    {/* <div id="1" className="home home-data-cont bg-home"> */}
      <div className="home-data-subcont-right">
        <div className="inner-cont">
          <h2 className="hero-title-1">We are</h2>
          <h1 className="hero-title-2" >India's Leading Skilling & Staffing Company</h1>
        </div>
        <a className="main-href" href="#9">
          {/*<ButtonKnowMoreHero text={"Book FREE COUNSELLING Session"} lgtext /> */}
        </a>
      </div>
    </div>
  );
};
export const SubCompCarousel1 = ({ bgImg }) => {
  return (
    <div id="1" className={`home home-data-cont bg-home ${bgImg}`}>
    {/* <div id="1" className="home home-data-cont bg-home"> */}
      <div className="home-data-subcont-right">
        <div className="inner-cont">
          {/* <h1 className="hero-title-2">Build an Incredible Nursing Career in Germany</h1> */}
        </div>
        <a className="main-href" href="#9">
          {/* <ButtonKnowMoreHero text={"Book FREE COUNSELLING Session"} lgtext /> */}
        </a>
      </div>
    </div>
  );
};

export const SubCompCarousel2 = ({ bgImg }) => {
  return (
    <div id="1" className={`home home-data-cont bg-home ${bgImg}`}>
    {/* <div id="1" className="home home-data-cont bg-home"> */}
      <div className="home-data-subcont-right">
        <div className="inner-cont">
          {/* <h1 className="hero-title-2">Build an Incredible Nursing Career in Germany</h1> */}
        </div>
        <a className="main-href" href="#9">
          {/* <ButtonKnowMoreHero text={"Book FREE COUNSELLING Session"} lgtext /> */}
        </a>
      </div>
    </div>
  );
};

export const SubCompCarousel3 = ({ bgImg }) => {
  return (
    <div id="1" className={`home home-data-cont bg-home ${bgImg}`}>
    {/* <div id="1" className="home home-data-cont bg-home"> */}
      <div className="home-data-subcont-right">
        <div className="inner-cont">
          {/* <h1 className="hero-title-2">Build an Incredible Nursing Career in Germany</h1> */}
        </div>
        <a className="main-href" href="#9">
          {/* <ButtonKnowMoreHero text={"Book FREE COUNSELLING Session"} lgtext /> */}
        </a>
      </div>
    </div>
  );
};

export const SubCompCarousel4 = ({ bgImg }) => {
  return (
    <div id="1" className={`home home-data-cont bg-home ${bgImg}`}>
    {/* <div id="1" className="home home-data-cont bg-home"> */}
      <div className="home-data-subcont-right">
        <div className="inner-cont">
          {/* <h1 className="hero-title-2">AI in Daily Life</h1> */}
        </div>
        <a className="main-href" href="#9">
          {/* <ButtonKnowMoreHero text={"Book FREE COUNSELLING Session"} lgtext /> */}
        </a>
      </div>
    </div>
  );
};


export const SubCompCarouselOpposite = ({ bgImg }) => {
  return (
    <div id="1" className={`home home-data-cont-left bg-home ${bgImg}`}>
    {/* <div id="1" className="home home-data-cont bg-home"> */}
      <div className="home-data-subcont-right">
        <div className="inner-cont">
          {/*<h1 className="hero-title-2">More than 500 Nursing Jobs in Germany</h1>*/}
        </div>
        <a className="main-href" href="#9">
          {/*<ButtonKnowMoreHero text={"Book FREE COUNSELLING Session"} lgtext /> */}
        </a>
      </div>
    </div>
  );
};

