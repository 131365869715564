import WideTextCard from '../../../utils/Cards/Wide Text Card/Wide Text Card';
import './contact.css';



const Contact = ({title, data}) => {
    return(
        <div className="section-container bg-gray-lite">
        <div className="section-title-cont">
          <hr className="section-title-line" />
          <h3 className="section-title">{title}</h3>
        </div>
        <div className="card-holder">
        {
          data.map(({name, number, email}) => {
            return(
              <WideTextCard name={name} number={number} email={email} />
            )
          })
        }
        </div>
      </div>
    )
}

export default Contact