import "./internationalBusiness.css";
import { TextComponent, OurOfferings } from "../../../Components/Our Services/International Business";
import { OurExperts, Contact } from "../../../Components/Study Abroad";
import { training_models, our_offerings,contactData } from "./data";

const InternationalBusiness = () => {
  return (
    <>
      <div className="home home-data-cont bg-contentDev">
        <div className="home-data-subcont-right">
          <div className="inner-cont">
            <h1 className="hero-title-2">
            Orion's Expertise for Your Business Growth
            </h1>
          </div>
        </div>
      </div>
      <TextComponent title={'About'} />
      <OurOfferings title={'Our Offerings'} data={our_offerings} />
      <OurExperts title={'Training Models'} data={training_models} onlyTitle={true} bg={'bg-lite-gray'} />
    <Contact title={'Contact'} data={contactData}/>
    </>
  );
};

export default InternationalBusiness;
