import { useState, useEffect } from "react";
import "./App.css";
import NavBar from "./Components/NavBar/NavBar";
import Home from "./Pages/Home/Home";
import Footer from "./Components/Footer/Footer";
import { MobileNavbar } from "./Components/NavBar/MobileNavbar";
import { Route, Routes } from "react-router";
import { RefundPolicy, PrivacyPolicy, TandC, ProductPricing } from "./Pages/Footer Pages";
import { AboutUs, Awards, CompanyProfile } from "./Pages/About Us Pages";
import { ForeignInternship, SkillDevelopment, InternationalSchooling } from "./Pages/Our Programs";
import {useLocation} from 'react-router-dom'
import StudentTestimonials from "./Pages/Other Pages/Student Testimonials";
import CSR from "./Pages/CSR/csr";
import {ContactUs, Payment} from './Pages/index';
import {ContentDev, InternationalBusiness, StudyAbroad, Ignou} from './Pages/Our Services'
import FeedbackPage from "./Pages/Other Pages/Feedback/Feedback";

function App() {
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const {pathname} = useLocation()

  function handleMobileMenuActive() {
    setMobileMenuActive(!mobileMenuActive);
  }
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  useEffect(() => {
    scrollToTop()
  }, [pathname])
  return (
    <div className="App">
      <NavBar
        mobileMenuActive={mobileMenuActive}
        handleMobileMenuActive={handleMobileMenuActive}
      />
      <MobileNavbar
        mobileMenuActive={mobileMenuActive}
        handleMobileMenuActive={handleMobileMenuActive}
      />
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/terms-and-conditions" element={<TandC />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/product-pricing" element={<ProductPricing />} />

        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/about-us/company-profile" element={<CompanyProfile />} />
        <Route path="/about-us/awards-and-recognition" element={<Awards />} />

        <Route path="/foreign-internship" element={< ForeignInternship/>} />
        <Route path="/skill-development" element={< SkillDevelopment/>} />
        <Route path="/international-schooling" element={< InternationalSchooling/>} />

        <Route path="/content-development" element={< ContentDev/>} />
        <Route path="/international-business" element={< InternationalBusiness/>} />
        <Route path="/study-abroad" element={< StudyAbroad/>} />
        
        <Route path="/ignou" element={<Ignou/>} />

        <Route path="/students-testimonials" element={<StudentTestimonials/>} />
        <Route path="/corporate-social-responsibility" element={<CSR/>} />
        <Route path="/contact-us" element={<ContactUs/>} />

        <Route path="/payment" element={<Payment/>} />
        <Route path="/feedback" element={<FeedbackPage/>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
