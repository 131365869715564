import "./keySectors.css";
import { BlueIconsSection } from "../../Home Page";
import { ButtonKnowMore } from "../../../utils/buttons";

const KeySector = ({ data, title, titp }) => {
  return (
    <>
      <div className="section-container">
        <div className="section-title-cont">
          <hr className="section-title-line" />
          <h3 className="section-title ">{title}</h3>
        </div>
        {!titp && <p className="p-text section-small-title ">We empower youth towards viable livelihood choices in diverse sectors such as</p>}
        <BlueIconsSection data={data} />
        {titp && <div className="know-more-a" >
          <a target="_blank" rel="noreferrer" style={{textDecoration:'none', margin: 'auto', marginTop: '1.5rem'}} href="https://www.orionsashi.com/"> <ButtonKnowMore text={'Know More'}/> </a>
        </div> }
      </div>
    </>
  );
};

export default KeySector;
