import "./internationalSchooling.css";
import { ButtonKnowMoreHero } from "../../../utils/buttons";
import {CounsForm} from '../../../Components/Study Abroad'
import { SchoolDetails } from "../../../Components/Our Programs";
import {schools_data} from './data'

const InternationalSchooling = () => {
  return (
    <>
      <div className="home home-data-cont bg-int-school">
        <div className="home-data-subcont-right">
          <div className="inner-cont">
            <h1 className="hero-title-2">
              International Collaboration for Higher Secondary Schools.
            </h1>
            {/* <h2 className="hero-title-extra" >Starting soon!</h2> */}
          </div>
          <a style={{textDecoration:'none'}} href="#9"><ButtonKnowMoreHero text={"Download Brochure"} lgtext /></a>
        </div>
      </div>
      <SchoolDetails title={'Our Associated Schools'} data={schools_data}  />
      <CounsForm title={'Download Brochure'} page={'intSchool'}/>
      <Contact title={"Contact"} />
    </>
  );
};

export default InternationalSchooling;

const Contact = ({ title }) => {
  return (
    <div className="section-container bg-gray-lite">
      <div className="section-title-cont">
        <hr className="section-title-line" />
        <h3 className="section-title">{title}</h3>
      </div>
      <div className="card-holder">
        <div
          className="card-landscape wide-text-card"
          style={{ textAlign: "center" }}
        >
          <h5 className="card-sub-title wc-title">Siddhartha Dey</h5>
          <div style={{ marginTop: "1rem" }}>
            <h5
              style={{
                fontSize: "1rem",
                lineHeight: "1.2rem",
                color: "#2f4152",
              }}
            >
              +91-9330345028
            </h5>
            <h5
              style={{
                fontSize: "0.9rem",
                lineHeight: "1.2rem",
                color: "#2f4152",
              }}
            >
              siddhartha.dey@orionedutech.com 
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

