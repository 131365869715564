import "./footerPages.css";

const PrivacyPolicy = () => {
  return (
    <div className="one-view">
      <div className="page-title">
        <h1>Privacy Policy</h1>
      </div>
      <p className="text-data one-view-sub-p">
        Please read these terms and conditions carefully before using this
        Website.
      </p>
      <p className="text-data one-view-sub-p">
        This document is an electronic record in terms of applicable Information
        Technology laws and the amended provisions pertaining to electronic
        records in various statutes as amended by the Information Technology
        laws. This electronic record has been generated by a computer system and
        does not require any physical or digital signatures for authentication.
      </p>
      <p className="text-data one-view-sub-p">
        Privacy Policy of the country makes an essential part of the Terms and
        Conditions, incorporated herein. If you have not yet reviewed the Orion
        Edutech Pvt. Ltd. Privacy Policy, then please do so prior to approving
        these Terms and Conditions.
      </p>
      <p className="text-data one-view-sub-p">
        Your privacy is important to us. We do not sell or forward or rent your
        personal information what you share with Orion Edutech Pvt. Ltd, to any
        third party for any purposes whatsoever. This Portal does not
        automatically save any specific personal information from you, (like
        name, phone number or e-mail address), that permits us to identify you
        individually.
      </p>
      <p className="text-data one-view-sub-p">
        If Orion Edutech Pvt. Ltd. Portal requests you to provide your personal
        information, you will be informed for the particular purposes for which
        the information is gathered and satisfactory security measures will be
        undertaken to safeguard your personal information.
      </p>
      <p className="text-data one-view-sub-p">
        Any information provided to this Portal will be protected from loss,
        misuse, unauthorized access or disclosure, alteration, or destruction.
      </p>
      <p className="text-data one-view-sub-p">
        Your use of Orion Edutech Pvt. Ltd. website signifies your acceptance of
        the Orion Edutech Pvt. Ltd. Privacy Policy.
      </p>
    </div>
  );
};
export default PrivacyPolicy;
