export const schools_data = [
  {
    image: "https://res.cloudinary.com/learnhat/image/upload/v1649424445/orion-new-website/international-schooling/a28c0ff4-50b6-4fff-ad1a-f8b520d083f3_mgjevx.jpg",
    name: "HARYANA (Kurukshetra)",
    wlink:'https://www.motherteresa.school/',
    text: "Mother Teresa Modern Public School",
    description:'Pipli-Ladwa Road, Mathana Chowki, Thanesar, Kurukshetra-136131'
  },
  {
    image: "https://res.cloudinary.com/learnhat/image/upload/v1649424441/orion-new-website/international-schooling/ee60c9be-69ed-4830-b807-22dd6d445355_azoxdv.jpg",
    name: "RAJASTHAN (Sri Ganganagar)",
    wlink:'http://www.sssadan.com/',
    text: "Saraswati Shikshan Sadan Convent Senior Secondary School",
    description:'11 LNP, Udyog Vihar, Sri Ganganagar, Rajasthan-335001'
  },
  {
    image: "https://res.cloudinary.com/learnhat/image/upload/v1650881133/lms-app/new-lms-website/Logo_of_BBIS_q9fgct.jpg",
    name: "PUNJAB (Barnala Road, Raikot)",
    wlink:'',
    text: "Budding Brains International School",
    description:'Barnala Road, Raikot, Dist. Ludhiana, Punjab-141009'
  },
  {
    image: "https://dcbdjixrxgopu.cloudfront.net/cglms/uploads/medias/Intl_school/Logo_Saffron%20City%20School.jpg",
    name: "PUNJAB (Chandigarh Road, Fatehgarh Sahib)",
    wlink:'',
    text: "Saffron City School",
    description:'Village: Kotla Bajwara, Chandigarh Road, Fatehgarh Sahib, Punjab-140406'
  },
  {
    image: "https://res.cloudinary.com/learnhat/image/upload/v1649424434/orion-new-website/international-schooling/b4ef1b0c-b1a2-4a03-bb02-69645b36db7d_iiajer.jpg",
    name: "GUJARAT",
    wlink:'',
    text: "",
    description:'',
  },

];
