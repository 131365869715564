import AwardsCard from "../../utils/Cards/Awards Card/AwardsCard";
import { dataAwardsNew } from "./data";

const Awards = () => {
  return (
    <div className="section-container bg-gray-lite">
      <div className="section-title-cont">
        <hr className="section-title-line" />
        <h3 className="section-title">Awards & Recognition</h3>
      </div>
      <div className="awardsCardHolder">
        {dataAwardsNew.map((item) => {
          return <AwardsCard image={item.image} text={item.text} />;
        })}
      </div>
    </div>
  );
};

export default Awards;
