/* eslint-disable */
import {  useState } from "react";
import CryptoJS from "crypto-js";

const Payment = () => {
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-") +
      " " +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(":")
    );
  }

  // 👇️ 2021-10-24 16:21:23 (yyyy-mm-dd hh:mm:ss)
  // console.log(formatDate(new Date()));

  let chargetotal = "13";
  let currency = "365";
  let paymentMethod = "M";
  let responseFailURL = "https://localhost:8643/webshop/response_failure.jsp";
  let responseSuccessURL =
    "https://localhost:8643/webshop/response_success.jsp";
  let sharedsecret = "Xz26yaXhFm";
  let storename = "3396021901";
  let timezone = "Asia/Calcutta";
  let transactionNotificationURL =
    "https://localhost:8643/webshop/transactionNotification";
  let txndatetime = formatDate(new Date());
  let txntype = "sale";
  let stringToExtendedHash = `${chargetotal}|${currency}|${paymentMethod}|${responseFailURL}|${responseSuccessURL}|${storename}|${timezone}|${transactionNotificationURL}|${txndatetime}|${txntype}`;
  var hashExtended = CryptoJS.HmacSHA256(stringToExtendedHash, sharedsecret);

  return (
    <div>
      <form
        method="post"
        action="https://www4.ipg-online.com/connect/gateway/processing"
      >
        <input type="hidden" name="txntype" value="sale" />
        <input type="hidden" name="timezone" value="Asia/Calcutta" />
        <input type="hidden" name="txndatetime" value={txndatetime} />
        <input type="hidden" name="hash_algorithm" value="HMACSHA256" />
        <input type="hidden" name="hashExtended" value={hashExtended} />
        <input type="hidden" name="storename" value="3396021901" />
        <input type="text" name="chargetotal" value="13" />
        <input type="hidden" name="currency" value="365" />
        <input size="50" type="hidden" name="mode" value="payonly" />
        <input size="50" type="hidden" name="sharedsecret" value="Xz26yaXhFm" />
        <input id="" name="full_bypass" size="20" value="false" type="hidden" />
        <input type="submit" value="Submit" />
      </form>
    </div>
  );
};

export default Payment;
