import "./wedoText.css";
import WideTextCard from "../../../utils/Cards/Wide Text Card/Wide Text Card";
import { ButtonKnowMore } from "../../../utils/buttons";

const cardsData = [
  {
    title: "Vision",
    text: "To ensure empowerment and sustainable livelihood through holistic skilling, guidance and counselling; to cater to corporates through end to end recruitment and staffing solutions.",
  },
  {
    title: "Mission",
    text: "To be the most preferred and sought after provider of training and development services for business, softskills and placement.",
  },
  
];

const WedoText = ({ title }) => {
  return (
    <div className="section-container">
      <div className="section-title-cont">
        <hr className="section-title-line" />
        <h3 className="section-title ">{title}</h3>
      </div>
      <div className="wedotext-sub-cont">
        <p className="p-text" >
        Marginalized sections of society, living under difficult conditions where they lack proper representation, power and status, are unable to claim their rights and opportunities to enhance their lives. Towards reducing this disparity, Orion Edutech has collaborated with Government/Non-Government agencies, PSUs and Corporate houses, towards making an attempt to extend a more sustainable livelihoods for such people. Orion Educational Society was founded to enable inclusive development and we impart mass skill training through impactful training methodologies and interactive course modules. We are driven by the vision of building India as the skill capital of the world and skill development for all is a critical part of this mission.
        </p>
        <a className="know-more-a" href="http://www.orionedusociety.org/">
          <ButtonKnowMore text={"Read More"} />
        </a>
      </div>
      <div className="card-holder" style={{marginTop:'3.5rem'}} >
        {cardsData.map((item) => {
          return <WideTextCard title={item.title} text={item.text} />;
        })}
      </div>
    </div>
  );
};

export default WedoText;
