import { OrangeButtonLarge } from "../../../utils/buttons/Buttons";
import "./clients.css";



const Clients = ({ csr, logos, title, studyabroad }) => {
  return (
    <div className="section-container" >
      {csr ? (
        <div className="section-title-cont">
        <hr className="section-title-line" />
        <h3 className="section-title">{title}</h3>
      </div>
      ) : (
        <div className="section-title-cont">
        <hr className="section-title-line" />
        <h3 className="section-title">{title}</h3>
      </div>
      )}
      <div className="card-holder">
        {csr
          ? logos.map((logo) => {
              return (
                <div className="sub-cont">
                  <img className="item" src={logo.link} alt="" />
                </div>
              );
            })
          : logos.map((logo) => {
              return (
                <div className="sub-cont">
                  <img className="item" src={logo.link} alt="" />
                </div>
              );
            })}
      </div>
      
      {studyabroad &&  <div className="kno-more-btnn-cont">
            <a href="#9">
              <OrangeButtonLarge text={'Book FREE COUNSELLING Session'} />
            </a>
          </div> }
    </div>
  );
};

export default Clients;
