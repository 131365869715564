import "./feedback.css";
import { useState,useEffect } from "react";
import { OrangeButtonLarge } from "../../utils/buttons/Buttons";
import axios from "axios";

// const data = {
//   name: "Test Employee",
//   empDesignation: "Test Designation",
//   empDOJ: "2020-05-01",
//   empPlace: "Kolkata",
//   empDeparrtment: "Account",
//   empReportingTo: "SKY",
//   empContactNo: "6548900058",
//   empEmail: "testemp@yahoo.in",
//   empHowManyMonthYear: "24 months",
//   empGrowthDev: "Test growth",
//   empExperience: "Test experience",
//   empRemarkableMoment: "Test moments",
//   empSuggestion: "Test suggestion",
//   signature: "Test Employee",
// };

const EmployeeFeedback = () => {
  const [status, setStatus] = useState(false);
  const [name, setName] = useState();
  const [empDesignation, setempDesignation] = useState();
  const [empDOJ, setempDOJ] = useState();
  const [empPlace, setempPlace] = useState();
  const [empDeparrtment, setempDeparrtment] = useState();
  const [empReportingTo, setempReportingTo] = useState();
  const [empContactNo, setempContactNo] = useState();
  const [empEmail, setempEmail] = useState();
  const [empHowManyMonthYear, setempHowManyMonthYear] = useState();
  const [empGrowthDev, setempGrowthDev] = useState();
  const [empExperience, setempExperience] = useState();
  const [empRemarkableMoment, setempRemarkableMoment] = useState();
  const [empSuggestion, setempSuggestion] = useState();
  const [employeeCode , setemployeeCode] = useState();
  const [password , setpassword] = useState();
  const [formResponse , setFormResponse] = useState('');
  const [emailFlag , setEmailFlag] = useState(false);
  const message = "Email is not valid";
  const signature = 'Orion_Edutech'

  const emailValidation = ()=>{
    const regEx = /^[A-Za-z0-9._]{3,}@[A-Za-z]{3,}[.]{1}[A-Za-z.]{2,6}/;
    if(regEx.test(empEmail)){
      setEmailFlag(true)
      // console.log(emailFlag)
    }
    else if(!regEx.test(empEmail)){
      setEmailFlag(false)
      // console.log(emailFlag)
    }
  }
  const baseUrl =
    "https://orionedutech.co.in/apis/learnhat_two/Orionedutechwebsite/saveFeedbackFormEmployee";

  useEffect(() => {
    emailValidation();
    
  }, [empEmail])
  

  const submitStudentFeedback = async () => {
    try {
      if (
        name &&
        empDesignation &&
        empDOJ &&
        empPlace &&
        empDeparrtment &&
        empReportingTo &&
        empContactNo &&
        empEmail &&
        empHowManyMonthYear &&
        empGrowthDev &&
        empExperience &&
        empRemarkableMoment &&
        empSuggestion &&
        signature &&
        employeeCode &&
        password &&
        emailFlag
        ) {
        const response = await axios.post(
          `${baseUrl}`,
          JSON.stringify({
            name,
            empDesignation,
            empDOJ,
            empPlace,
            empDeparrtment,
            empReportingTo,
            empContactNo,
            empEmail,
            empHowManyMonthYear,
            empGrowthDev,
            empExperience,
            empRemarkableMoment,
            empSuggestion,
            signature,
            employeeCode,
            password
          })
        );
        console.log(response.data)
        if (response.data.issuccess) {
          setStatus(true);
        }
        else if(!response.data.issuccess){
          window.alert("Please Enter correct Employee id and password!")
        }
        if(response.data.issuccess === 1){
          setFormResponse('Your details have been submitted successfully.')
        }
        else if(response.data.issuccess === 0){
          setFormResponse('Incorrect Credentials. Please enter correct employee id and password.')
        }
      } 
      else{
        if(emailFlag === true){
          emailValidation();
          window.alert("Please Fill all the fileds");
        }
        else{
          window.alert("Please enter correct email id")
        }
        // window.alert("Please Fill all the fileds");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="section-container">
      <div className="card-holder">
        {status ? (
          <div className="card-landscape">
            <p className="form-submit-text">
              {/* Your details have been submitted successfully. */}
              {formResponse}
            </p>
          </div>
        ) : (
          <div className="form-box  form-box-newest">
            <input
              className="input-name"
              type="text"
              placeholder="Name"
              name=""
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              className="input-name"
              type="text"
              placeholder="Designation"
              name=""
              id="designation"
              value={empDesignation}
              onChange={(e) => setempDesignation(e.target.value)}
            />
            <input
              className="input-name"
              type="text"
              placeholder="Employee Id"
              name=""
              id="empid"
              value={employeeCode}
              onChange={(e) => setemployeeCode(e.target.value)}
            />
            <input
              className="input-name"
              type="password"
              placeholder="Password"
              name=""
              id="password"
              value={password}
              onChange={(e) => setpassword(e.target.value)}
            />
            <p className="random-ptag">
              Date of joining
            </p>
            <input
              className="input-name"
              type="date"
              placeholder="Date Of joining"
              name=""
              id="doj"
              value={empDOJ}
              onChange={(e) => setempDOJ(e.target.value)}
            />
            {/* <p className="random-ptag">
              Plaese rate the below five ponits from 1 to 5
            </p> */}
            <input
              className="input-name"
              type="text"
              placeholder="Place"
              name=""
              id="place"
              value={empPlace}
              onChange={(e) => setempPlace(e.target.value)}
            />
            <input
              className="input-name"
              type="text"
              placeholder="Department"
              name=""
              id="department"
              value={empDeparrtment}
              onChange={(e) => setempDeparrtment(e.target.value)}
            />
            <input
              className="input-name"
              type="text"
              placeholder="Reporting To"
              name=""
              id="reporting"
              value={empReportingTo}
              onChange={(e) => setempReportingTo(e.target.value)}
            />
            <input
              className="input-name"
              type="number"
              placeholder="Contact Number"
              name=""
              id="number"
              value={empContactNo}
              onChange={(e) => setempContactNo(e.target.value)}
            />
            <input
              className="input-name"
              type="email"
              placeholder="Email ID"
              name=""
              id="email"
              value={empEmail}
              onChange={(e) => setempEmail(e.target.value)}
            />
            {emailFlag ? null : <p className="email-validation">{message}</p>}
            <input
              className="input-name"
              type="text"
              placeholder="How many months/ years you have been associated with Orion & where?"
              name=""
              id="years"
              value={empHowManyMonthYear}
              onChange={(e) => setempHowManyMonthYear(e.target.value)}
            />
            <input
              className="input-name"
              type="text"
              placeholder="Briefly explain about your growth & development in this organization."
              name=""
              id="desc"
              value={empGrowthDev}
              onChange={(e) => setempGrowthDev(e.target.value)}
            />
            <input
              className="input-name"
              type="text"
              placeholder="Share your experience."
              name=""
              id="experience"
              value={empExperience}
              onChange={(e) => setempExperience(e.target.value)}
            />
            <input
              className="input-name"
              type="text"
              placeholder="Any remarkable moment/incident in the office."
              name=""
              id="incident"
              value={empRemarkableMoment}
              onChange={(e) => setempRemarkableMoment(e.target.value)}
            />
            <input
              className="input-name"
              type="text"
              placeholder="Any suggestion for the company."
              name=""
              id="suggestion"
              value={empSuggestion}
              onChange={(e) => setempSuggestion(e.target.value)}
            />
            {/* <input
              className="input-name"
              type="text"
              placeholder="Signature"
              name=""
              id=""
              value={signature}
              onChange={(e) => setSignature(e.target.value)}
            /> */}
            <div className="this-btn-cont" onClick={submitStudentFeedback}>
              <OrangeButtonLarge text={"Submit"} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeFeedback;
