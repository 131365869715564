export const training_models = [
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640933488/orion-new-website/orion_international/traningmodel/1_pflcj8.jpg",
    name: "Franchise Business Model",
  },

  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640934053/orion-new-website/orion_international/traningmodel/2_aha4by.jpg",
    name: "Blended Training",
  },

  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640934169/orion-new-website/orion_international/traningmodel/3_mi1ikk.jpg",
    name: "Live Instructor Training",
  },

  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640934257/orion-new-website/orion_international/traningmodel/4_1_eom31n.jpg",
    name: "Video Conferencing",
  },
];

export const our_offerings = [
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640932622/orion-new-website/orion_international/offering/Business_Model_dg2oti.svg",
    title: "Franchise Business Model",
  },

  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640932863/orion-new-website/orion_international/offering/Content_Development_kthja2.svg",
    title: "Content Development",
  },

  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640932950/orion-new-website/orion_international/offering/University_s8ylut.svg",
    title: "Tie-up with Universities",
  },

  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640933023/orion-new-website/orion_international/offering/Corporate_Training_nablb9.svg",
    title: "Corporate Training",
  },

  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640933238/orion-new-website/orion_international/offering/Government_q7w61t.svg",
    title: "Government Tie-ups",
  },

  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640933299/orion-new-website/orion_international/offering/School_innubn.svg",
    title: "School Tie-ups",
  },
];

export const contactData = [
  {
    name: "Somnath Acharya ",
    number: "+91 9830409181",
    email: "somnathacharya@orionedutech.com",
  },
];