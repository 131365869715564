import "./aboutUsNew.css";
import { CompanyProfile,  Leadership, Team } from "../../Components/About US";
import {teamData, leadersData} from './data'

const AboutUs = () => {
  return (
    <>
      <div className="home bg-aboutus">
        <h1 className="banner-over-text cl-white" >About Us</h1>
      </div>
      <CompanyProfile/>
      <Leadership title={'Leadership'} data={leadersData} />
      <Team title={'Team'} data={teamData} abtUs />
      
    </>
  );
};

export default AboutUs;
