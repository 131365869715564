

const TextComponent = ({ title }) => {
  return (
  
      <div className="section-container">
        <div className="section-title-cont">
          <hr className="section-title-line" />
          <h3 className="section-title ">{title}</h3>
        </div>
        <div className="card-holder company-text-box ">
          <p className="p-text comp-text ">
          A Journey from Skill India to Skill Global' is the motto with which Orion International operates. With 16 years of deep expertise in skill development, training and staffing across diverse sectors, Orion Edutech is here to help you grow your business exponentially in the country or geography of your operations. Our successful business blueprints are going to give you the thrust your business needs. 
          </p>
          <p className="p-text comp-text ">
          Entrepreneurs across different nations in Africa and South-East Asia have seen immense growth partnering with Orion. Come, be a part of this growing, thriving family of successful entrepreneurs.
          </p>
        </div>
      </div>
    
  );
};

export default TextComponent;
