export const cse_logos_colored = [
    
    {
       "link":"https://res.cloudinary.com/learnhat/image/upload/v1640596368/orion-new-website/csr_keysector/effort/SDG_1_k3tbx3.svg",
       "name":"No Poverty SDG 1",
    },

    {
       "link":"https://res.cloudinary.com/learnhat/image/upload/v1640596455/orion-new-website/csr_keysector/effort/SDG_2_ly7lhl.svg",
       "name":"Zero Hunger SDG 2",
    },

    {
       "link":"https://res.cloudinary.com/learnhat/image/upload/v1640596481/orion-new-website/csr_keysector/effort/SDG_4_maeok1.svg",
       "name":"Universal Education SDG 4",
    },

     {
       "link":"https://res.cloudinary.com/learnhat/image/upload/v1640596626/orion-new-website/csr_keysector/effort/SDG_5_axrln5.svg",
       "name":"Gender Equality SDG 5",
    },

      {
       "link":"https://res.cloudinary.com/learnhat/image/upload/v1640596665/orion-new-website/csr_keysector/effort/SDG_6_duum43.svg",
       "name":"Clean Water And Sanitation SDG 6",
    },

    {
       "link":"https://res.cloudinary.com/learnhat/image/upload/v1640596694/orion-new-website/csr_keysector/effort/SDG_8_mqp4bk.svg",
       "name":"Decent Work And Economic Growth SDG 8",
    },

    {
       "link":"https://res.cloudinary.com/learnhat/image/upload/v1640596870/orion-new-website/csr_keysector/effort/SDG_10_c3kpiu.svg",
       "name":"Reduced Inequalities SDG 10",
    },

    {
       "link":"https://res.cloudinary.com/learnhat/image/upload/v1640596903/orion-new-website/csr_keysector/effort/SDG_11_gw8tn6.svg",
       "name":"Sustainable Cities And Communities SDG 11",
    },
    
    {
       "link":"https://res.cloudinary.com/learnhat/image/upload/v1640596937/orion-new-website/csr_keysector/effort/SDG_17_qvtgvt.svg",
       "name":"Partnerships For The Goals SDG 17",
    },
    

]

export const key_sectors_icon_blue = [
    
   {
      "link":"https://res.cloudinary.com/learnhat/image/upload/v1640595614/orion-new-website/csr_keysector/Information_Technology_dymrqt.svg",
      "name":"Information Technology",
   },
   {
      "link":"https://res.cloudinary.com/learnhat/image/upload/v1640595690/orion-new-website/csr_keysector/Electronics_hex7t5.svg",
      "name":"Electronics",
   },
   {
      "link":"https://res.cloudinary.com/learnhat/image/upload/v1640595739/orion-new-website/csr_keysector/Travel_Tourism_xit32y.svg",
      "name":"Travel & Tourism",
   },
   {
      "link":"https://res.cloudinary.com/learnhat/image/upload/v1640595795/orion-new-website/csr_keysector/Retail_two_mpa5hd.svg",
      "name":"Retail",
   },
   {
      "link":"https://res.cloudinary.com/learnhat/image/upload/v1640595854/orion-new-website/csr_keysector/Networking_naz3dd.svg",
      "name":"Networking",
   },
   {
      "link":"https://res.cloudinary.com/learnhat/image/upload/v1640595917/orion-new-website/csr_keysector/Hospitality_two_wugvsk.svg",
      "name":"Hospitality",
   },
   {
      "link":"https://res.cloudinary.com/learnhat/image/upload/v1640595971/orion-new-website/csr_keysector/Agriculture_djl0hx.svg",
      "name":"Agriculture",
   },
   {
      "link":"https://res.cloudinary.com/learnhat/image/upload/v1640596050/orion-new-website/csr_keysector/Mobile_Laptop_Repairing_kpgknz.svg",
      "name":"Mobile, Laptop Repairing",
   },
    {
      "link":"https://res.cloudinary.com/learnhat/image/upload/v1640596130/orion-new-website/csr_keysector/Healthcare_three_tntmto.svg",
      "name":"Healthcare",
   },
   {
      "link":"https://res.cloudinary.com/learnhat/image/upload/v1640596205/orion-new-website/csr_keysector/Appareal_ttwjkv.svg",
      "name":"Apparel Manufacturing",
   }
   

]

export const csrLogos = [
  {link:'https://res.cloudinary.com/learnhat/image/upload/v1643788818/orion-new-website/image002_lcxltg.jpg' },
  {link:'https://res.cloudinary.com/learnhat/image/upload/v1638792963/lms-app/new-lms-website/logos%20csr/8_REC_Ltd_badoj0.png' },
  {link: 'https://res.cloudinary.com/learnhat/image/upload/v1638792963/lms-app/new-lms-website/logos%20csr/14_Samsung_e5qc8n.png'},
  {link: 'https://res.cloudinary.com/learnhat/image/upload/v1638792963/lms-app/new-lms-website/logos%20csr/13_ICICI_Foundation_j1jusg.jpg'},
  {link: 'https://res.cloudinary.com/learnhat/image/upload/v1638792963/lms-app/new-lms-website/logos%20csr/12_Diageo_fmtdyx.png'},
  {link: 'https://res.cloudinary.com/learnhat/image/upload/v1638792963/lms-app/new-lms-website/logos%20csr/10_GE_xyyyg8.png'},
  {link: 'https://res.cloudinary.com/learnhat/image/upload/v1638792963/lms-app/new-lms-website/logos%20csr/9_Alstompng_kmy7fn.png'},
  {link: 'https://res.cloudinary.com/learnhat/image/upload/v1638792963/lms-app/new-lms-website/logos%20csr/7_Indian_Oil_wj4hf6.png'},
  {link: 'https://res.cloudinary.com/learnhat/image/upload/v1638792963/lms-app/new-lms-website/logos%20csr/11_Phillips_jjkvlg.png'},
  {link: 'https://res.cloudinary.com/learnhat/image/upload/v1638792962/lms-app/new-lms-website/logos%20csr/2_Crompton_Greaves_g1xd1s.png'},
  {link: 'https://res.cloudinary.com/learnhat/image/upload/v1638792962/lms-app/new-lms-website/logos%20csr/6_NTPC_mmvcih.png'},
  {link: 'https://res.cloudinary.com/learnhat/image/upload/v1638792962/lms-app/new-lms-website/logos%20csr/4_Ingersoll_Rand_n8ynaf.png'},
  {link: 'https://res.cloudinary.com/learnhat/image/upload/v1638792962/lms-app/new-lms-website/logos%20csr/5_ITC_gx4iqi.png'},
  {link: 'https://res.cloudinary.com/learnhat/image/upload/v1638792962/lms-app/new-lms-website/logos%20csr/3_Larson___Toubro_ypunwj.png'},
  {link: 'https://res.cloudinary.com/learnhat/image/upload/v1638792962/lms-app/new-lms-website/logos%20csr/1_HDFC_Bank_mew0nl.png'},
]

export const csrFocusGroup = [
   {
      text:"Inmates of tihar jail (with capablity and recoginition to rehabilitate themselves as a  self-relaint individual"
   },
   {
      text:'Differently abled candidates'
   },
   {
      text:'Candidates who suffer from subnormal capablities'
   },
   {
      text:'Widows of Martyrs'
   },
]

