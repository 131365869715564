import "./FocusAreaSection.css";
import { FocusAreaCard } from "../../../utils";

const dataArray = [
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640335600/orion-new-website/main%20home/Focus%20Area/1_rsmrak.png",
    title: "Staffing",
    description:
      "Holistic solutions for corporates in sourcing the right talent through innovative flexi-staffing",
    wlink: "https://staffing.orionedutech.in/",
  },
  {
    image:
      "https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/future_skill.png",
     // "https://res.cloudinary.com/learnhat/image/upload/v1640335600/orion-new-website/main%20home/Focus%20Area/2_pigkb6.png",
    title: "Skill Development",
    description:
      "Over 400,000 youth trained in diverse skills through government and on government skilling programs",
    link: "/skill-development",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640335600/orion-new-website/main%20home/Focus%20Area/3_qoghgm.png",
    title: "Study Abroad",
    description:
      "Your expert partner in your journey to study at best universities around the world",
    link: "/study-abroad",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641449900/orion-new-website/main%20home/Focus%20Area/iStock-917843960_ojmcc2.jpg",
    title: "Foreign Apprenticeship",
    description:
      "Orion Sashi Pvt ltd is empanelled as the Sending Organisation for Technical Intern Training Program  (TITP) Japan",
    link: "/foreign-internship",
  },
];

const FocusAreaSection = () => {
  return (
    <div className="section-container">
      <div className="section-title-cont">
        <hr className="section-title-line" />
        <h3 className="section-title">Our Work</h3>
      </div>

      <div className="card-holder">
        {dataArray.map((item) => {
          return (
            <FocusAreaCard
              image={item.image}
              title={item.title}
              wlink={item.wlink}
              link={item.link}
              description={item.description}
              height="17rem"
            />
          );
        })}
      </div>
    </div>
  );
};

export default FocusAreaSection;

// {
//   /* <div className={`focus-area-img-cont ${item.image}`}>
//   {hover === `focus-area-img-cont ${item.image}` ? (
//     <div className="hover-card">
//       <p className="hover-card-title">{item.title}</p>
//       <p className="hover-card-desc">{item.description}</p>
//       {item.wlink ? (
//         <a
//           target="_blank"
//           rel="noreferrer"
//           className="hover-card-know"
//           href={item.wlink}
//         >
//           <p>Know More</p>
//         </a>
//       ) : (
//         <Link className="hover-card-know" to={item.link}>
//           Know More
//         </Link>
//       )}
//     </div>
//   ) : (
//     <p className="card-title">{item.title}</p>
//   )}
// </div>; */
// }
