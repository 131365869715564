import "./skillDevelopment.css";
import { ClickableIcons } from "../../../Components/Our Programs";
import { Leadership } from "../../../Components/About US";
import { programs_data, blue_icons_data } from "./data";

const SkillDevelopment = () => {
  return (
    <>
    <div className="home home-data-cont bg-skill-dev">
        <div className="home-data-subcont-right" >
        <div className="inner-cont" >
        
        <h1 className="hero-title-2" >Skill Development for Bharat</h1>
        </div>
        </div>
      </div>
      <ClickableIcons title={'Courses'} data={blue_icons_data}/>
      <Leadership title={'Programs'} data={programs_data} />
    </>
  );
};

export default SkillDevelopment;
