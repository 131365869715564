import "./footerPages.css";
import FeaturedCourses from "../../Components/Home Page/Featured Courses/FeaturedCourses";

export const featuredCourses = [
  {
    name: "Essentials Of Business Communication",
    img: "https://dcbdjixrxgopu.cloudfront.net/cglms/uploads/medias/courses/business_communication.jpg",
    desc: "Learn to communicate confidently, smartly and efficiently to find success in your work environment and feel like a winner.",
    price:1
  },
  {
    name: "Diploma in Hardware Networking",
    img: "https://dcbdjixrxgopu.cloudfront.net/cglms/uploads/medias/courses/diploma_hardware_networking.jpg",
    desc: "Computer Hardware and Networking is an intriguing field in todayâ€™s world. The Advanced Diploma in hardware and Networking ",
    price:1
  },
  {
    name: "Diploma in Corporate Management",
    img: "https://dcbdjixrxgopu.cloudfront.net/cglms/uploads/medias/courses/diploma_corporate_management.jpg",
    desc: "The course Diploma in Corporate Management (DICM) aims at training participants in fundamentals of Accounts,",
    price:1
  },
  {
    name: "Diploma in Hospitality Management",
    img: "https://dcbdjixrxgopu.cloudfront.net/cglms/uploads/medias/courses/diploma_hospitality_management.jpg",
    desc: "A course of hospitality management focuses on the application of management principles in the field of Hospitality.",
    price:1
  },
  {
    name: "German Nursing Program",
    img: "https://dcbdjixrxgopu.cloudfront.net/cglms/uploads/medias/test_course/52_course_1651227560.jpg",
    desc: "A great opportunity to work as Nurse in a leading chain of Nursing Home and Hospitals In Germany.",
    price:200000
  },
];
const ProductPricing = () => {
  return (
      <>
    <div className="one-view">
      <div className="page-title">
        <h1>Product Pricing</h1>
      </div>
    </div>
    <FeaturedCourses title={"Featured Courses"} data={featuredCourses} productPricingPage />

    </>
  );
};
export default ProductPricing;
