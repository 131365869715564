import "./clickableIcons.css";
import { useState } from "react";

const ClickableIcons = ({ title, data }) => {
  const [active, setActive] = useState(null);
  console.log(active);

  const sendActive = (id) => {
    console.log(id);
    setActive(id);
  };

  return (
    <>
      <div className="section-container bg-gray-lite">
        <div className="section-title-cont">
          <hr className="section-title-line" />
          <h3 className="section-title">{title}</h3>
        </div>
        <div className="card-holder" style={{ position: "relative" }}>
          {data.map(({ image, name, subCategory, id }) => {
            return (
              <ClickIconsComp
                image={image}
                name={name}
                subCategory={subCategory}
                id={id}
                active={active}
                setActive={sendActive}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ClickableIcons;

const ClickIconsComp = ({
  image,
  name,
  subCategory,
  id,
  active,
  setActive,
}) => {
  // const func = () => {
  //   if (id === active) {
  //     setActive("");
  //   } else {
  //     setActive(id);
  //   }
  // };

  return (
    <>
      <div
        // onClick={func}
        className={
          active === id ? "click-ic-cont click-ic-cont-active" : "click-ic-cont"
        }
      >
        <img
          className={
            active === id
              ? "item-click-ic-active item-click-ic"
              : "item-click-ic"
          }
          src={image}
          alt=""
        />
        <h5 className="text-click-ic">{name}</h5>

        {active && active === id && (
          <div className="show-box">
            <h1>hallo</h1>
          </div>
        )}
      </div>
    </>
  );
};
