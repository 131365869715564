import { Teamcard } from "../../../utils";
import "./team.css";



const Team = ({title, data, titp}) => {
  return (
    <div className="section-container">
      <div className="section-title-cont">
        <hr className="section-title-line" />
        <h3 className="section-title">{title}</h3>
      </div>
      <div className="card-holder card-holder-team " >
        {data.map(({ name, title, image }) => {
          return <Teamcard name={name} title={title} image={image} height={titp ? '11rem' : '22.5rem'} titp={titp ? true : false} />;
        })}
      </div>
    </div>
  );
};

export default Team;
