import "./schoolDetails.css";
import { FocusAreaCardSchoolCustom } from "../../../utils";

const SchoolDetails = ({title, data}) => {
  return (
    <div>
      <div className="section-container bg-gray-lite">
        <div className="section-title-cont">
          <hr className="section-title-line" />
          <h3 className="section-title">{title}</h3>
        </div>
        <div className="card-holder">
          {data.map(({ image, name, link, text, description }) => {
            return (
              <FocusAreaCardSchoolCustom
                image={image}
                name={name}
                link={link}
                text={text}
                description={description}
                height={"16rem"}
                leaders
                onlyTitle={text ? false : true}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SchoolDetails;
