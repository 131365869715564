import "./cseColorLogos.css";

const CSEColorLogos = ({ title, logos }) => {
  return (
    <>
      <div className="section-container bg-gray-lite ">
        <div className="section-title-cont">
          <hr className="section-title-line" />
          <h3 className="section-title">{title}</h3>
        </div>
        <div className="card-holder card-holder-clients ">
        {logos.map((item) => {
          return (
            <div className="sub-cont-key-sec-color-ic">
              <img className="item-color-ic" src={item.link} alt="" />
              <h5 className="text-color-ic" >{item.name}</h5>
            </div>
          );
        })}
      </div>
      </div>
    </>
  );
};

export default CSEColorLogos;
