import "./ourOfferings.css";

const OurOfferings = ({ title, data }) => {
  return (
    <div className="section-container bg-apna-blue">
      <div className="section-title-cont">
        <hr className="section-title-line" />
        <h3 className="section-title cl-white">{title}</h3>
      </div>
      <div className="card-holder">
        {data.map((item) => {
          return <OfferingCard item={item} />;
        })}
      </div>
    </div>
  );
};

export default OurOfferings;

const OfferingCard = ({ item }) => {
  return (
    <div className="only-card offer-card">
      <div className="cont-offer-img-card" >
      <img className='offer-card-img' src={item.image} alt="" />
      </div>
      <p className="offer-card-title" >{item.title}</p>
    </div>
  );
};
