import "./csr.css";
import { WedoText, FocusGroup, CSEColorLogos, KeySector, Contact } from "../../Components/CSR Page";
import Clients from "../../Components/Home Page/ClientsAndPartners/Clients";
import { cse_logos_colored, key_sectors_icon_blue, csrLogos, csrFocusGroup } from "./data";


const CSR = () => {
  return (
    <>
      <div className="hero-csr">
        <div className="overlay-bg">
        </div>
      </div>
      <WedoText title={'Corporate Sponsored Skilling'}/>
      
      <CSEColorLogos title={'Orion’s CSS efforts are aligned to multiple Sustainable Development Goals (SDGs) of the UN'} logos={cse_logos_colored} colored />
      <FocusGroup title={'Focus Group'} data={csrFocusGroup} />
      <KeySector data={key_sectors_icon_blue}  title={'Key Sectors'}/>
      <Clients csr logos={csrLogos} title={'Our Clients & Partners'} />
      {/* <div className="card-holder">
        <div className="key-sec-images-cont">
          <div>
            <img
              className="small-img"
              src="https://res.cloudinary.com/learnhat/image/upload/v1640352431/orion-new-website/main%20home/key-sectors/220-SM838347_aumyo7.png"
              alt=""
            />
            <img
              className="small-img"
              src="https://res.cloudinary.com/learnhat/image/upload/v1640352431/orion-new-website/main%20home/key-sectors/220-SM941967_kv3z6z.png"
              alt=""
            />
          </div>
          <img
            className="large-img"
            src="https://res.cloudinary.com/learnhat/image/upload/v1640352432/orion-new-website/main%20home/key-sectors/13-SM236816_wq8g3q.png"
            alt=""
          />
        </div>
        <div className="key-sec-images-cont">
          <img
            className="large-img"
            src="https://res.cloudinary.com/learnhat/image/upload/v1640352432/orion-new-website/main%20home/key-sectors/16-SM176086_mnhkmv.png"
            alt=""
          />
          <div>
            <img
              className="small-img"
              src="https://res.cloudinary.com/learnhat/image/upload/v1640352431/orion-new-website/main%20home/key-sectors/11-SM273128_bpydpo.png"
              alt=""
            />
            <img
              className="small-img"
              src="https://res.cloudinary.com/learnhat/image/upload/v1640352431/orion-new-website/main%20home/key-sectors/120-SM904713_nutiz6.png"
              alt=""
            />
          </div>
        </div>
      </div> */}
      <Contact title={'Contact'} />
    </>
  );
};

export default CSR;
