

const TextComponent = ({ title }) => {
  return (
  
      <div className="section-container">
        <div className="section-title-cont">
          <hr className="section-title-line" />
          <h3 className="section-title ">{title}</h3>
        </div>
        <div className="card-holder company-text-box ">
          <p className="p-text comp-text ">
          An apparel Merchandiser is a bridge between a buyer and the industry. The diploma in apparel
          merchandising provides an aspirant with the specialist knowledge of developing apparel ranges and
          accommodating trends. It also lends industry insight, commercial understanding, access to a range of
          positions in merchandising and buying.
          </p>
          <p className="p-text comp-text ">
          The aim of this course is to provide students with the fundamental skills and knowledge necessary to
          guide and operate in real fashion processes in both local and international realities. The Merchandiser
          plays a major role right from order booking process to Shipment. The critical role of merchandising
          department can be divided into three major heads: Order Booking Process, Product Development
          Process and Pre – Production Process.
          </p>
        </div>
      </div>
    
  );
};

export default TextComponent;
