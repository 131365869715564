import { ButtonKnowMoreHero } from "../../Components/Buttons/Buttons";
import "../../Pages/Our Services/ignou/Ignou.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";


export const CarouselIGNOU = () => {
  return (
    <>
      <Carousel autoPlay infiniteLoop>
       <SubCompCarouselIgnou bgImg={'bg-home-ignou'} />
       {/*<SubCompCarouselOpposite bgImg={'bg-home2'} />*/}
       <SubCompCarouselIgnou2 bgImg={'bg-home-ignou-2'} />
      </Carousel>
    </>
  );
};


export const SubCompCarouselIgnou = ({ bgImg }) => {
  return (
    <div id="1" className={`home home-data-cont bg-home ${bgImg}`}>
    {/* <div id="1" className="home home-data-cont bg-home"> */}
      <div className="home-data-subcont-right">
        <div className="inner-cont">
          {/* <h1 className="hero-title-2">Build an Incredible Nursing Career in Germany</h1> */}
        </div>
        <a className="main-href" href="#9">
          {/* <ButtonKnowMoreHero text={"Book FREE COUNSELLING Session"} lgtext /> */}
        </a>
      </div>
    </div>
  );
};
export const SubCompCarouselIgnou2 = ({ bgImg }) => {
  return (
    <div id="1" className={`home home-data-cont bg-home ${bgImg}`}>
    {/* <div id="1" className="home home-data-cont bg-home"> */}
      <div className="home-data-subcont-right">
        <div className="inner-cont">
          {/* <h1 className="hero-title-2">Build an Incredible Nursing Career in Germany</h1> */}
        </div>
        <a className="main-href" href="#9">
          {/* <ButtonKnowMoreHero text={"Book FREE COUNSELLING Session"} lgtext /> */}
        </a>
      </div>
    </div>
  );
};

