import "./foreignInternship.css";
// css coming from footerpage.css
import { KeySector } from "../../../Components/CSR Page/index";
import {
  key_sectors_titp,
  photos_titp,
  aboutTitpCard1,
  aboutTitpCard2,
} from "./data";
import { Team } from "../../../Components/About US";

const ForeignInternship = () => {
  return (
    <>
      <div className="home bg-titp-cont home-data-cont bg-titp">
        <div className="home-data-subcont-right titp-home-data-subcont-right">
          <div className="inner-cont">
            <h1 className="hero-title-2 titp-hero-title">
              Giving Wings <br /> To Careers
            </h1>
          </div>
        </div>
      </div>
      <AboutTitp
        title={"About TITP"}
        data1={aboutTitpCard1}
        data2={aboutTitpCard2}
      />
      <KeySector data={key_sectors_titp} title={"Key Sectors"} titp />
      {/* <SuccessStories data={successStoriesData} title={"Testimonials"} /> */}
      <Team title={"Successful Students"} data={photos_titp} titp />
      <Contact title={"Contact"} />
    </>
  );
};

export default ForeignInternship;

const Contact = ({ title }) => {
  return (
    <div className="section-container bg-gray-lite">
      <div className="section-title-cont">
        <hr className="section-title-line" />
        <h3 className="section-title">{title}</h3>
      </div>
      <div className="card-holder">
        <div
          className="card-landscape wide-text-card"
          style={{ textAlign: "center" }}
        >
          <h5 className="card-sub-title wc-title">Prerna Joshi</h5>
          <div style={{ marginTop: "1rem" }}>
            <h5
              style={{
                fontSize: "1rem",
                lineHeight: "1.2rem",
                color: "#2f4152",
              }}
            >
              +91 9836846251
            </h5>
            <h5
              style={{
                fontSize: "0.9rem",
                lineHeight: "1.2rem",
                color: "#2f4152",
              }}
            >
              prerna@orionedutech.com
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

const AboutTitp = ({ title, data1, data2 }) => {
  return (
    <>
      <div className="section-container bg-gray-lite">
        <div className="section-title-cont">
          <hr className="section-title-line" />
          <h3 className="section-title">{title}</h3>
        </div>
        <div className="card-holder">
          {data1.map((item) => {
            return (
              <div className="card-landscape wide-text-card">
                <h5 className="card-sub-title wc-title">{item.title}</h5>
                {/* <p >{text}</p> */}
                <ul className="p-text">
                  {item.data.map((itemin) => {
                    return (
                      <>
                        <li>{itemin}</li>
                      </>
                    );
                  })}
                </ul>
              </div>
            );
          })}
          {data2.map((item) => {
            return (
              <div className="card-landscape wide-text-card">
                <h5 className="card-sub-title wc-title">{item.title}</h5>
                <ul className="p-text">
                  {item.data.map((itemin) => {
                    return (
                      <>
                        <li>{itemin}</li>
                      </>
                    );
                  })}
                </ul>
                <h5
                  className="card-sub-title wc-title"
                  style={{ marginTop: "1.5rem" }}
                >
                  {item.title2}
                </h5>
                <ul className="p-text">
                  {item.data2.map((itemin) => {
                    return (
                      <>
                        <li>{itemin}</li>
                      </>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
