import "./footerPages.css";

const TandC = () => {
  return (
    <div className="one-view">
      <div className="page-title" ><h1>Terms and Conditions</h1></div>
      <div className="one-view-sub">
        <h4>INTELLECTUAL PROPERTY RIGHTS COPYRIGHT PROTECTION</h4>
        <p className="one-view-sub-p" >
          All content comprised on this website, such as logos, graphics, text,
          button icons, audio and visual clips, downloads, data compilations and
          software are the property of www.orionedutech.com and protected by the
          Indian Copyright law. The composing of all the content on this site is
          the exclusive property of www.orionedutech.com.
        </p>
      </div>
      <div className="one-view-sub">
        <h4>TRADEMARKS</h4>
        <p className="one-view-sub-p" >
          That www.orionedutech.comindicated on www.orionedutech.com website are
          registered trademarks of www.orionedutech.com. And Orion Edutech Pvt.
          Ltd. reserves all rights of copy or using the same. Nothing contained
          in the Website should be construed as granting, by implication or
          otherwise, any license or right to use the Marks without express
          written permission of the owner or rights holder with respect to such
          use.
        </p>
      </div>
      <div className="one-view-sub">
        <h4>GOVERNING LAW AND JURISDICTION</h4>
        <p className="one-view-sub-p" >
        These terms and conditions will be interpreted only in accordance with the laws of Union of India. In respect of all matters/disputes arising out of, in connection with or in relation to these terms & conditions or any other conditions related hereto, only the Courts/Tribunals/Commissions of Kolkata shall have the exclusive jurisdiction to deal with the same.
        </p>
      </div>
      <div className="one-view-sub">
        <h4>DISCLAIMER OF WARRANTIES</h4>
        <p className="one-view-sub-p" >
        You as a user of the Website acknowledge and agree that Orion Edutech Pvt. Ltd. has no liability for any errors or omissions in the Website, including but not limited to the Materials, whether provided by Orion Edutech Pvt. Ltd. or third parties. You further acknowledge and agree that Orion Edutech Pvt. Ltd. makes no warranty or representation of whatsoever nature as to the availability of the Website or any portion thereof, that the Website or portions thereof may be inaccessible for various time periods whether due to Internet service provider problems or otherwise. The information on this site has been included in good faith and is for general purposes only. It should not be relied upon for any specific purpose and no representation or warranty is given as regards its accuracy or completeness.Neither Orion Edutech Pvt. Ltd., nor their affiliates, officers, employees, representatives or agents shall be liable for any loss, damage or expense arising out of any access to or use of this site or any site linked to it.
        </p>
      </div>
    </div>
  );
};
export default TandC;
