import "./ourExperts.css";

import { FocusAreaCard } from "../../../utils";
import { WhiteButtonLarge } from "../../../utils/buttons/Buttons";

const OurExperts = ({ data, title, studyabroad, onlyTitle, bg }) => {
  return (
    <>
      <div className={`section-container ${bg ? bg : "bg-apna-blue"}`}>
        <div className="section-title-cont">
          <hr className="section-title-line" />
          <h3
            className={`section-title ${
              bg !== "bg-apna-blue" ? "cl-white" : ""
            }`}
          >
            {title}
          </h3>
        </div>
        <div className="card-holder">
          {data.map(({ image, name, title, textArray }) => {
            return (
              <FocusAreaCard
                title={title}
                image={image}
                textArray={textArray}
                name={name}
                height={"17rem"}
                leaders
                onlyTitle={onlyTitle}
              />
            );
          })}
        </div>
        {studyabroad && (
          <div className="kno-more-btnn-cont">
            <a href="#9">
              <WhiteButtonLarge text={"Book FREE Session"} />
            </a>
          </div>
        )}
      </div>
    </>
  );
};

export default OurExperts;
