import "./counsForm.css";
import { useState } from "react";
import axios from "axios";
import { OrangeButtonLarge } from "../../../utils/buttons/Buttons";

const CounsForm = ({ title, page }) => {
  const [status, setStatus] = useState(false);
  const baseUrl =
    "https://orionedutech.co.in/apis/learnhat_two/Studyabroad/sendMainStudeyAbbroad";
  const urlIntSchool =
    "https://orionedutech.co.in/apis/learnhat_two/Internationalschool/sendMainForInternationalSchool";
  const [username, setUsername] = useState("");
  const [useremail, setUseremail] = useState("");
  const [userphone, setUserphone] = useState("");
  const [country, setCountry] = useState("");

  const submitDataStudyabroad = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}`,
        JSON.stringify({
          username: username,
          useremail: useremail,
          userphone: userphone,
          destination_country: country,
        })
      );
      if (response.data.emailSend) {
        setStatus(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const submitDataInternationalSchooling = async () => {
    try {
      const response = await axios.post(
        `${urlIntSchool}`,
        JSON.stringify({
          from_name: username,
          from_email_id: useremail,
          from_phone_number: userphone,
          from_name_of_school: country,
        })
      );
      // console.log(response);
      if (response.data.emailSend) {
        setStatus(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="section-container" id="9">
      <div className="section-title-cont">
        <hr className="section-title-line" />
        <h3 className="section-title ">{title}</h3>
      </div>
      <div className="card-holder">
        {status ? (
          <div className="card-landscape">
            <p className="form-submit-text">
              {page === "intSchool"
                ? "Thank you for the details. Please check your email shortly for the International Schooling brochure."
                : "Your details have been submitted successfully. Our team will get back to you shortly."}
            </p>
          </div>
        ) : (
          <div className="form-box">
            <input
              className="input-name"
              type="text"
              placeholder="Name"
              name=""
              id=""
              onChange={(e) => setUsername(e.target.value)}
            />
            <div>
              <input
                type="text"
                placeholder="Email ID"
                name=""
                id=""
                onChange={(e) => setUseremail(e.target.value)}
              />
              <input
                type="text"
                placeholder="Phone Number"
                name=""
                id=""
                maxLength={10}
                onChange={(e) => setUserphone(e.target.value)}
              />
            </div>
            <div>
              <input
                type="text"
                placeholder={
                  page === "intSchool"
                    ? "Name of School"
                    : "Your Destination Country"
                }
                name=""
                id=""
                onChange={(e) => setCountry(e.target.value)}
              />
              <span
                onClick={
                  page === "intSchool"
                    ? submitDataInternationalSchooling
                    : submitDataStudyabroad
                }
              >
                <OrangeButtonLarge
                  text={
                    page === "intSchool" ? "Download Brochure" : "Book FREE Session"
                  }
                />
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CounsForm;
