import './footerPages.css';

const RefundPolicy = () => {
    return(
        <div className="one-view" >
            <div className="page-title" ><h1>Cancellation &  Refund policy</h1></div>
            <p className="refund one-view-sub-p one-view-sub" >Orion Edutech Pvt. Ltd. clearly declare that any fees received from any student whatever the case may be, is non-refundable under any circumstances once Orion Edutech Pvt. Ltd. has processed on the basis of his/her/their/its respective application unless otherwise specifically mentioned.</p>
        </div>
    )
};
export default RefundPolicy;