import "./FeaturedCourses.css";
import { CourseCard } from "../../../utils/Cards/Course Card/CourseCard";

const FeaturedCourses = ({ title, data, productPricingPage, id }) => {
  return (
    <div
      id={id}
      className={
        productPricingPage
          ? "section-container "
          : "section-container bg-gray-lite"
      }
    >
      {!productPricingPage && (
        <div className="section-title-cont">
          <hr className="section-title-line" />
          <h3 className="section-title">{title}</h3>
        </div>
      )}
      <div className="card-holder">
        {data.map((item) => {
          return (
            <CourseCard
              img={item.img}
              name={item.name}
              desc={item.desc}
              price={item.price}
              productPricingPage={productPricingPage}
            />
          );
        })}
      </div>
      {productPricingPage ? (
        <div className="playStoreBtnCont">
        <a
          className="playStoreLargeButton"
          href="https://orionedutech.in/#3"
          rel="noreferrer"
        >
          View Courses
        </a>
      </div>
      ) : (
        <div className="playStoreBtnCont">
          <a
            className="playStoreLargeButton"
            href="https://play.google.com/store/apps/details?id=com.learnhath.OrionEdutech"
            target="_blank"
            rel="noreferrer"
          >
            View Courses
          </a>
        </div>
      )}
    </div>
  );
};

export default FeaturedCourses;
