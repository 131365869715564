import "./focusGroup.css";

const FocusGroup = ({title, data, other}) => {
  return (
    <div className="section-container bg-apna-blue">
      <div className="section-title-cont">
        <hr className="section-title-line" />
        <h3 className="section-title cl-white">{title}</h3>
      </div>
      {!other ? <p className="focus-group-sub-title" >Our existing CSS projects aim to provide training with a rehabilitative and holistic perspective to:</p> : null}
      <div className="card-holder">
        {
          data.map(item => {
            return(<FocusGroupCard text={item.text}/>)
          })
        }
      </div>
    </div>
  );
};

export default FocusGroup;

const FocusGroupCard = ({ text }) => {
  return (
    <div className="card focus-group-card">
      <p>{text}</p>
    </div>
  );
};
