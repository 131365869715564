import "./contentDev.css";
import { About } from "../../../Components/Our Services/Content Dev";
import { aboutData, logosArray, achievemnetsData, contactData } from "./data";
import Clients from "../../../Components/Home Page/ClientsAndPartners/Clients";
import { FocusGroup } from "../../../Components/CSR Page";
// import { Contact } from "../../../Components/Study Abroad";

const ContentDev = () => {
  return (
    <>
      <div className="home home-data-cont bg-contentDev">
        <div className="home-data-subcont-right">
          <div className="inner-cont">
            <h1 className="hero-title-2 cl-black">
              Your Trusted Partner in Your Content Development Journey
            </h1>
          </div>
        </div>
      </div>
      <About title={"About Content Grill"} data={aboutData} />
      <Clients title={"Our Clients"} logos={logosArray} />
      <FocusGroup title={"Our Achievements"} data={achievemnetsData} other />
      <Contact2 title={"Contact"} data={contactData} />
    </>
  );
};

export default ContentDev;

//Coming from foreign internship main page
const Contact2 = ({ title, data }) => {
  return (
    <div className="section-container bg-gray-lite">
      <div className="section-title-cont">
        <hr className="section-title-line" />
        <h3 className="section-title">{title}</h3>
      </div>
      <div className="card-holder">
        {data.map((item) => {
          return (
            <div
              className="card-landscape wide-text-card"
              style={{ textAlign: "center" }}
            >
              <h5 className="card-sub-title wc-title">{item.name}</h5>
              <div style={{marginTop:'1rem'}} >
              <h5 style={{fontSize:'1rem',lineHeight:'1.2rem', color:'#2f4152'}} >{item.phone}</h5>
              <h5 style={{fontSize:'0.8rem',lineHeight:'1.2rem', color:'#2f4152'}} >{item.email}</h5>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
