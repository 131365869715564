import './contactUs.css';

const ContactUs = () => {
    return (
        <div className="one-view" >
            <h1 className="page-title" >Contact us</h1>
            <div className="card-holder-full contactus-sub-cont">
                <div className="contactus-sub-cont-1 card-landscape" >
                    <div className="contactus-boxs">
                        <h3>Address</h3>
                        <p>Orion House, 28, Rajarhat Main Road,
                            Chinar Park, Kolkata,</p>
                            <p> West Bengal - 700157</p>
                    </div>
                    <div className="contactus-boxs" >
                        <h3>Call Us</h3>
                        <p>18002004112</p>
                    </div>
                    <div className="contactus-boxs" >
                        <h3>Email Us</h3>
                        <p>info@orionedutech.com</p>
                    </div>
                </div>
                <div className="contactus-sub-cont-2 card-landscape" >
                <iframe title="any" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3682.8086375131825!2d88.44100101453735!3d22.62362008515659!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89fd27b4b15a5%3A0xa4d95687dc6e52aa!2sPS+Aviator!5e0!3m2!1sen!2sin!4v1519642147119" width="100%" height="100%" frameborder="0"  allowfullscreen=""></iframe>
                </div>
            </div>
        </div>
    )
}

export default ContactUs