import "./studyAbroad.css";
import { ButtonKnowMoreHero } from "../../../utils/buttons";
import { OurSuccess } from "../../../Components/Home Page";
import { OurExperts, WhatWeDo, SuccessStories, Contact, CounsForm } from "../../../Components/Study Abroad";

import { perfect_university, what_we_do_data, ourExpertsData, contactData, videoDataMobile, videoDataWeb } from "./data";
import Clients from "../../../Components/Home Page/ClientsAndPartners/Clients";

const StudyAbroad = () => {
  return (
    <>
      <div className="home home-data-cont bg-about-us">
        <div className="home-data-subcont-right">
          <div className="inner-cont">
            <h1 className="hero-title-2">
              Achieve Your Dream Education Abroad With Us
            </h1>
          </div>
          <ButtonKnowMoreHero text={"Book FREE COUNSELLING Session"} lgtext />
        </div>
      </div>
      <OurSuccess studyabroad />
      <WhatWeDo title={'What We Do'} data={what_we_do_data}  />
      <OurExperts data={ourExpertsData} title={'Meet Our Experts'} studyabroad />
      <Clients
        logos={perfect_university}
        title={"Find Your Perfect University"}
        studyabroad
      />
      <SuccessStories title={'Hear Success Stories'} dataMob={videoDataMobile} dataWeb={videoDataWeb} />
      {/* <DreamDest title={'Choose Your Dream Destination'} studyabroad /> */}
      <CounsForm title={'Book Your Free Session'} />
      <Contact title={'Contact'} data={contactData} />
    </>
  );
};

export default StudyAbroad;
