export const contactData = [
  {
    name: "Soumya Brata Dhar",
    email: "sbd@orionedutech.com",
    phone: "9830130903",
  },

  {
    name: "Nabanita Banerjee",
    email: "nabanita@orionedutech.com",
    phone: "9330345072",
  },

  {
    name: "Pratip Sarkar",
    email: "content@orionsoftechcontentgrill.com",
    phone: "98756707271",
  },
];

export const logosArray = [
  {
    link:
      "https://res.cloudinary.com/learnhat/image/upload/v1640926225/orion-new-website/contentgrill%20/our_clients/1_1_qrwtq6.png",
  },

  {
    link:
      "https://res.cloudinary.com/learnhat/image/upload/v1640926262/orion-new-website/contentgrill%20/our_clients/2_vvsqdg.png",
  },

  {
    link:
      "https://res.cloudinary.com/learnhat/image/upload/v1640929384/orion-new-website/contentgrill%20/our_clients/3_jcpfww.png",
  },

  {
    link:
      "https://res.cloudinary.com/learnhat/image/upload/v1640929432/orion-new-website/contentgrill%20/our_clients/4_mgogso.jpg",
  },

  {
    link:
      "https://res.cloudinary.com/learnhat/image/upload/v1640929472/orion-new-website/contentgrill%20/our_clients/5_sxg4b8.jpg",
  },

  {
    link:
      "https://res.cloudinary.com/learnhat/image/upload/v1640929526/orion-new-website/contentgrill%20/our_clients/6_w0l3xr.png",
  },
  {
    link:
      "https://res.cloudinary.com/learnhat/image/upload/v1640929556/orion-new-website/contentgrill%20/our_clients/7_evclxh.png",
  },
  {
    link:
      "https://res.cloudinary.com/learnhat/image/upload/v1640929587/orion-new-website/contentgrill%20/our_clients/8_enyzvv.png",
  },
  {
    link:
      "https://res.cloudinary.com/learnhat/image/upload/v1640929660/orion-new-website/contentgrill%20/our_clients/9_dgqqpr.jpg",
  },
  {
    link:
      "https://res.cloudinary.com/learnhat/image/upload/v1640929702/orion-new-website/contentgrill%20/our_clients/10_v2zs8t.jpg",
  },
  {
    link:
      "https://res.cloudinary.com/learnhat/image/upload/v1640929737/orion-new-website/contentgrill%20/our_clients/11_bq3qje.png",
  },
  {
    link:
      "https://res.cloudinary.com/learnhat/image/upload/v1640929797/orion-new-website/contentgrill%20/our_clients/12_codmti.png",
  },
  {
    link:
      "https://res.cloudinary.com/learnhat/image/upload/v1640929929/orion-new-website/contentgrill%20/our_clients/13_vw4vnw.jpg",
  },
  {
    link:
      "https://res.cloudinary.com/learnhat/image/upload/v1640929976/orion-new-website/contentgrill%20/our_clients/14_ctwnmv.jpg",
  },
  {
    link:
      "https://res.cloudinary.com/learnhat/image/upload/v1640930029/orion-new-website/contentgrill%20/our_clients/15_npihc1.png",
  },
  {
    link:
      "https://res.cloudinary.com/learnhat/image/upload/v1640930057/orion-new-website/contentgrill%20/our_clients/16_o3olib.png",
  },
  {
    link:
      "https://res.cloudinary.com/learnhat/image/upload/v1640930088/orion-new-website/contentgrill%20/our_clients/17_zaa817.png",
  },
  {
    link:
      "https://res.cloudinary.com/learnhat/image/upload/v1640930181/orion-new-website/contentgrill%20/our_clients/18_vjqjce.jpg",
  },
  {
    link:
      "https://res.cloudinary.com/learnhat/image/upload/v1640930229/orion-new-website/contentgrill%20/our_clients/19_y1kw8v.png",
  },
  {
    link:
      "https://res.cloudinary.com/learnhat/image/upload/v1640930268/orion-new-website/contentgrill%20/our_clients/20_wpanqd.png",
  },
  {
    link:
      "https://res.cloudinary.com/learnhat/image/upload/v1640930313/orion-new-website/contentgrill%20/our_clients/21_urzijb.png",
  },
];

export const achievemnetsData = [
  {
    text: "Developing content for more than 15 years",
  },

  {
    text: "Developed more than 1000 courseware on specific skill development. IT Skills and Communicative English",
  },

  {
    text: "Developed multimedia based learning matrial on Domain Skills, Effective Communication, Soft Skill and so on.",
  },

  {
    text: "Developed more than 200 various books on behavioral skills, soft skills, leadership Skills, enterpreneurship skills",
  },

  {
    text: "Developed printed and digital content in all regional languages",
  },

  {
    text: "Established network of over 400 subject matter experts",
  },
  {
    text: "Specialized in developing various vocational coursewares",
  },
  {
    text: "Translated minimum 200 subject matters in multiple national languages",
  },
  {
    text: "Designed Shelf learning Materials responsive to all technology",
  },
  {
    text: "Facilitated more than 2.5 lakh learners",
  },
];

export const aboutData = [
    {
        image:'https://res.cloudinary.com/learnhat/image/upload/v1641207043/orion-new-website/iStock-1127921799_efated.jpg',
        title:'What We Do',
        dataArray:[
            'Learning Management System', 'Web Design & Development', 'E-learning Audio Visual Content', 'Logo Design', 'Textual Content', 'Banner Design', 'Assessment Guide', 'Magazine Design', 'Training-of-Trainer Service', 'Formatting', 'Translation Services', 'Question Bank Development'
        ]
    },
    {
        image:'https://res.cloudinary.com/learnhat/image/upload/v1641207042/orion-new-website/iStock-639819378_nsqhxt.jpg',
        title:'Our Process',
        dataArray:[
            'Deployment of Subject Matter Experts', 'Research and Identification of Training Need', 'Development of Course Outline', 'Development of Instructional Design/ High Level Design', 'Review', 'Storyboarding', 'Development of First Draft/ Script', 'Review', 'Modification as per Client’s Feedback', 'Review', 'Development of Final Draft', 'Tracking'
        ]
    },
]
