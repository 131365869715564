import TestimonialCard from "../../utils/Cards/Testimonial Card/TestimonialCard";

const dataTestimonials = [
  {
    image:
      "https://www.orionedutech.com/images/student_files/student_files_1482923227_3.jpg",
    name: "Nabam Apu",
    grad: "23.05.15",
    wd: "16 months",
    ctc: "12000",
    descShort:"Orion Edutech changed my life thoroughly. While I was pursuing my course in Hospitality I was unsure whether to work or not. I consulted my trainers and they advised me to appear for the interviews confidently.",
    desc: "Orion Edutech changed my life thoroughly. While I was pursuing my course in Hospitality I was unsure whether to work or not. I consulted my trainers and they advised me to appear for the interviews confidently. I appeared for an interview at The Royal Orchid, Bangalore and got through it. It was an emotional moment for me, leaving my friends at Orion and heading towards a new beginning- The beginning of my professional life. My life completely changed after joining The Royal Orchid. The staff is very cooperative and I learnt a lot from them. Today I am a confident professional who is ready to face challenges of life with a smile.",
    link: "/",
  },
  // {
  //   image: "https://www.orionedutech.com/images/student_files/student_files_1482923227_4.jpg",
  //   name: "Kamal Baruah",
  //   grad: "23.02.15",
  //   wd: "16 months",
  //   ctc: "12000",
  //   descShort:"I remember my trainers at Orion Edutech always told me that I have major MTI problem and everyone did their best to rectify the MTI issues of mine. At that point of time, I never realized how important it will be for my career.",
  //   desc: "I remember my trainers at Orion Edutech always told me that I have major MTI problem and everyone did their best to rectify the MTI issues of mine. At that point of time, I never realized how important it will be for my career. But, now I have understood that Communication is a very important tool for a hotelier to have a long and successful career. Thanks to all the trainers of Orion Edutech Pvt. Ltd, the support staff and the placement team for placing me at The Royal Orchid Bangalore.",
  //   link: "",
  // },
  {
    image: "https://www.orionedutech.com/images/student_files/student_files_1482923227_6.jpg",
    name: "Minal Gogoi",
    grad: "02.03.16",
    wd: "4 months",
    ctc: "15500",
    descShort:"First of all a special thanks to The Directorate of Skill Development and Employment Generation, Arunachal Pradesh. I am grateful to Arunachal Pradesh Government for giving me this training opportunity.",
    desc: "First of all a special thanks to The Directorate of Skill Development and Employment Generation, Arunachal Pradesh. I am grateful to Arunachal Pradesh Government for giving me this training opportunity. We have heard that There is no short cut to hard work but this opportunity provided by the government is really a short cut to make ourselves established. A special mention about Orion Edutech Pvt. Ltd., their staff took utmost care of us from the beginning till the placement. A special thanks to all the trainers who worked hard for us so that we can attain our goals and support our family in the long run. Without their enduring support it would have been impossible for me to make myself a part of the APOLLO team.",
    link: "",
  },
  {
    image: "https://www.orionedutech.com/images/student_files/student_files_1489143932_1.jpg",
    name: "Tana Nan",
    grad: "24.04.15",
    wd: "14 months",
    ctc: "12000",
    descShort:"The journey was a dream come true for me where I got myself transformed to a professional. Now I face the world with lots of confidence. Thanks to Orion Edutech for making me what I am today and giving me a complete new identity",
    desc: "The journey was a dream come true for me where I got myself transformed to a professional. Now I face the world with lots of confidence. Thanks to Orion Edutech for making me what I am today and giving me a complete new identity",
    link: "",
  },
  {
    image: "https://www.orionedutech.com/images/student_files/student_files_1489143982_1.jpg",
    name: "Olak Litin",
    grad: "24.04.15",
    wd: "14 months",
    ctc: "25000",
    descShort:"When I came for the training in Hospitality at Orion Edutech Pvt. Ltd., Kolkata, I never knew that my life will change within four months. Now I am a proud employee of Apollo Hospitals. Thanks Orion Edutech for providing me this opportunity.",
    desc: "When I came for the training in Hospitality at Orion Edutech Pvt. Ltd., Kolkata, I never knew that my life will change within four months. Now I am a proud employee of Apollo Hospitals. Thanks Orion Edutech for providing me this opportunity.",
    link: "",
  },
];

const StudentTestimonials = () => {
  return (
    <div className="section-container">
      {/* <div className="page-title">
        <h1>Student Testimonials</h1>{" "}
      </div > */}
      <div className="card-holder">
      {dataTestimonials.map((item) => {
        return (
          <TestimonialCard
            image={item.image}
            name={item.name}
            grad={item.grad}
            wd={item.wd}
            ctc={item.ctc}
            descShort={item.descShort}
            desc={item.desc}
            link={item.link}
          />
        );
      })}
      </div>
    </div>
  );
};

export default StudentTestimonials;
