export const perfect_university = [
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1640590476/orion-new-website/find_your_perfect_university/1_Wroclaw_University_ko66ms.png",
    name: "University 1",
  },
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1640590573/orion-new-website/find_your_perfect_university/2_Vistula_University_upxrai.png",
    name: "University 2",
  },
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1640590633/orion-new-website/find_your_perfect_university/3_University_Of_Latvia_siklvm.png",
    name: "University 3",
  },
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1640590678/orion-new-website/find_your_perfect_university/4_ISM_Germany_saque6.png",
    name: "University 4",
  },
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1640590717/orion-new-website/find_your_perfect_university/5_Lublin_university_of_Technology_pbi79e.png",
    name: "University 5",
  },
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1640590770/orion-new-website/find_your_perfect_university/6_Warsaw_Management_University_y0l5ll.png",
    name: "University 6",
  },
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1640590820/orion-new-website/find_your_perfect_university/7_Lazarski_University_sqav02.png",
    name: "University 7",
  },
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1640590867/orion-new-website/find_your_perfect_university/8_Marie_Curie_University_g6ec12.png",
    name: "University 8",
  },
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1640590907/orion-new-website/find_your_perfect_university/9_Norquest_College_ocguwx.png",
    name: "University 9",
  },
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1640590959/orion-new-website/find_your_perfect_university/10_University_of_Leichester_u6eaap.png",
    name: "University 10",
  },
];

export const successStoriesData = [
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640594667/orion-new-website/Vid_1_utz7cg.png",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640594678/orion-new-website/Vid_2_gehnrg.png",
  },
];

export const what_we_do_data = [
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640671636/orion-new-website/study_abroad/1_2_nmqq5p.jpg",
    title: "Personalized Counselling",
    description:
      "India’s finest counsellors support you from beginning to end.",
  },

  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640671778/orion-new-website/study_abroad/2_2_y2ilyy.jpg",
    title: "IELTS Coaching",
    description: "Ace your IELTS with coaching from India’s best trainers.",
  },

  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640671833/orion-new-website/study_abroad/3_3_rq5uxg.jpg",
    title: "Financial Aid Support",
    description:
      "Loan and scholarship support for a smoother admission process.",
  },

  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640671886/orion-new-website/study_abroad/4_2_qntmag.jpg",
    title: "Passpost, VISA, Travel and Accommodation",
    description:
      "Your partner in your journey of educational excellence all the way.",
  },
];

export const ourExpertsData = [
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640672695/orion-new-website/WhatsApp-Image-2021-12-28-at-11.25.37-AM_m9hmdt.png",
    name: "Mr. Sharoon Rubinstein S",
    title: "Lead Trainer",
    textArray: [
      "More than 11years IELTS training experience",
      "CELTA certified",
      "Formely, Foreign Language/ESL instructor at University of Cambridge",
    ],
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641371491/orion-new-website/Anasua_Bhowal_jrm6if.jpg",
    name: "Anusua Bhowal",
    title: " Counsellor",
    textArray: [
      "Over 8 years of academic counselling experience",
      "Worked with over thousand students in their study abroad journey",
      "Excellent rapport with students and understanding their psyche",
    ],
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1641371491/orion-new-website/Joydeb_Adak_l7oipk.jpg",
    name: "Joydeb Adak",
    title: " Counsellor",
    textArray: [
      "Over 14 years of rich experience in education",
      "Specialist in foreign counselling and marketing",
    ],
  },
];

export const contactData = [
  {
    name: "Manjari Singh ",
    number: "+91 93303 45136",
    email: "manjari.singh@orionedutech.com",
  },
];

export const videoDataMobile = [
  {
    id: "1",
    image:
      "https://res.cloudinary.com/learnhat/video/upload/v1641289010/orion-new-website/corporate_alience_awards/videos/Testomonial_2_ygnjyo.mp4",
  },

  {
    id: "2",
    image:
      "https://res.cloudinary.com/learnhat/video/upload/v1641289257/orion-new-website/corporate_alience_awards/videos/Testomonial_1_soa76g.mp4",
  },

  {
    id: "3",
    image:
      "https://res.cloudinary.com/learnhat/video/upload/v1641289327/orion-new-website/corporate_alience_awards/videos/Testimonial_8_wile2v.mp4",
  },

  {
    id: "4",
    image:
      "https://res.cloudinary.com/learnhat/video/upload/v1641289458/orion-new-website/corporate_alience_awards/videos/Testimonial_7_hqxzya.mp4",
  },

  {
    id: "5",
    image:
      "https://res.cloudinary.com/learnhat/video/upload/v1641289512/orion-new-website/corporate_alience_awards/videos/Testimonial_6_ju7qiy.mp4",
  },

  {
    id: "6",
    image:
      "https://res.cloudinary.com/learnhat/video/upload/v1641289558/orion-new-website/corporate_alience_awards/videos/Testimonial_5_rpg684.mp4",
  },

  {
    id: "7",
    image:
      "https://res.cloudinary.com/learnhat/video/upload/v1641289629/orion-new-website/corporate_alience_awards/videos/Testimonial_3_zzdbic.mp4",
  },
];
export const videoDataWeb = [
  {
    id: "1",
    image:
      "https://res.cloudinary.com/learnhat/video/upload/v1641289010/orion-new-website/corporate_alience_awards/videos/Testomonial_2_ygnjyo.mp4",
    id2: "2",
    image2:
      "https://res.cloudinary.com/learnhat/video/upload/v1641289257/orion-new-website/corporate_alience_awards/videos/Testomonial_1_soa76g.mp4",
  },

  {
    id: "3",
    image:
      "https://res.cloudinary.com/learnhat/video/upload/v1641289327/orion-new-website/corporate_alience_awards/videos/Testimonial_8_wile2v.mp4",
    id2: "4",
    image2:
      "https://res.cloudinary.com/learnhat/video/upload/v1641289458/orion-new-website/corporate_alience_awards/videos/Testimonial_7_hqxzya.mp4",
  },

  {
    id: "5",
    image:
      "https://res.cloudinary.com/learnhat/video/upload/v1641289512/orion-new-website/corporate_alience_awards/videos/Testimonial_6_ju7qiy.mp4",
    id2: "6",
    image2:
      "https://res.cloudinary.com/learnhat/video/upload/v1641289558/orion-new-website/corporate_alience_awards/videos/Testimonial_5_rpg684.mp4",
  },
];
