import "./feedback.css";
import { useState, useEffect } from "react";
import { OrangeButtonLarge } from "../../utils/buttons/Buttons";
import axios from "axios";

// const dataDemo = {
//   name: "Test Student",
//   stuCourseName: "Test Course",
//   stuCommenceDate: "2022-05-30",
//   stuClassConductSched: "5",
//   stuTrainerSkill: "4",
//   stuStudyMaterial: "3",
//   stuOnTimeTraining: "5",
//   stuPersonnelBehaviour: "5",
//   stuComment: "Testing",
//   signature: "Test Student",
// };
const StudentFeedback = () => {
  const [status, setStatus] = useState(false);
  const [name, setName] = useState();
  const [stuCourseName, setStuCourseName] = useState();
  const [stuCommenceDate, setStuCommenceDate] = useState();
  const [stuClassConductSched, setStuClassConductSched] = useState(1);
  const [stuTrainerSkill, setStuTrainerSkill] = useState(1);
  const [stuStudyMaterial, setStuStudyMaterial] = useState(1);
  const [stuOnTimeTraining, setStuOnTimeTraining] = useState(1);
  const [stuPersonnelBehaviour, setStuPersonnelBehaviour] = useState(1);
  const [stuComment, setStuComment] = useState();
  const [signature, setSignature] = useState();
  const [stuEmail, setstuEmail] = useState();
  const [emailFlag , setEmailFlag] = useState(false);
  const message = "Email is not valid";

  const emailValidation = ()=>{
    const regEx = /^[A-Za-z0-9._]{3,}@[A-Za-z]{3,}[.]{1}[A-Za-z.]{2,6}/;
    if(regEx.test(stuEmail)){
      setEmailFlag(true)
      // console.log(emailFlag)
    }
    else if(!regEx.test(stuEmail)){
      setEmailFlag(false)
      // console.log(emailFlag)
    }
  }

  const baseUrl =
    "https://orionedutech.co.in/apis/learnhat_two/Orionedutechwebsite/saveFeedbackFormStudent";

    useEffect(() => {
    emailValidation();
    }, [stuEmail])

    const submitStudentFeedback = async () => {
    try {
      if (
        name &&
        stuCourseName &&
        stuEmail && 
        stuCommenceDate &&
        stuClassConductSched &&
        stuTrainerSkill &&
        stuStudyMaterial &&
        stuOnTimeTraining &&
        stuPersonnelBehaviour &&
        stuComment &&
        signature && 
        emailFlag 
      ) {
        const response = await axios.post(
          `${baseUrl}`,
          JSON.stringify({
            name,
            stuCourseName, 
            stuEmail, 
            stuCommenceDate,
            stuClassConductSched,
            stuTrainerSkill,
            stuStudyMaterial,
            stuOnTimeTraining,
            stuPersonnelBehaviour,
            stuComment,
            signature,
          })
        );
        if (response.data.issuccess) {
          setStatus(true);
        }
      } else {
        if(emailFlag === true){
          emailValidation();
          window.alert("Please Fill all the fields");
        }
        else{
          window.alert("Please enter correct email id")
        }
        // window.alert("Please Fill all the fileds");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="section-container">
      <div className="card-holder">
        {status ? (
          <div className="card-landscape">
            <p className="form-submit-text">
              Your details have been submitted successfully.
            </p>
          </div>
        ) : (
          <div className="form-box form-box-new">
            <input
              className="input-name"
              type="text"
              placeholder="Name"
              name=""
              id=""
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              className="input-name"
              type="text"
              placeholder="Name of the Course"
              name=""
              id=""
              value={stuCourseName}
              onChange={(e) => setStuCourseName(e.target.value)}
            />
            <input
              className="input-name"
              type="email"
              placeholder="Email ID"
              name=""
              id="email"
              value={stuEmail}
              onChange={(e) => setstuEmail(e.target.value)}
            />
            {emailFlag ? null : <p className="email-validation">{message}</p>}
            <p className="random-ptag">Date Of Commencement</p>
            <input
              className="input-name"
              type="date"
              placeholder="Date Of Commencement"
              name=""
              id=""
              value={stuCommenceDate}
              onChange={(e) => setStuCommenceDate(e.target.value)}
            />
            <p className="random-ptag">
              Take a few moments to rate your satisfaction from 1 to 5
            </p>
            <p className="random-ptag" style={{marginBottom:'1rem'}}>
              1-Poor  2-Average  3-Good  4-Very Good  5-Excellent
            </p>
            <p className="title-ptag">
              1. Class conducted as per schedule: {stuClassConductSched}
            </p>
            <input
              className="input-name"
              type="range"
              placeholder="1. Class conducted as per schedule"
              name=""
              id=""
              step={1}
              min={1}
              max={5}
              value={stuClassConductSched}
              onChange={(e) => setStuClassConductSched(e.target.value)}
            />
            <p className="title-ptag">
              2. Trainers training skills: {stuTrainerSkill}
            </p>
            <input
              className="input-name"
              type="range"
              placeholder="2. Trainers training skills"
              name=""
              id=""
              step={1}
              min={1}
              max={5}
              value={stuTrainerSkill}
              onChange={(e) => setStuTrainerSkill(e.target.value)}
            />
            <p className="title-ptag">
              3. Course study materials or supporting: {stuStudyMaterial}
            </p>
            <input
              className="input-name"
              type="range"
              placeholder="3. Course study materials or supporting"
              name=""
              id=""
              step={1}
              min={1}
              max={5}
              value={stuStudyMaterial}
              onChange={(e) => setStuStudyMaterial(e.target.value)}
            />
            <p className="title-ptag">
              4. On-time training delivered as scheduled: {stuOnTimeTraining}
            </p>
            <input
              className="input-name"
              type="range"
              placeholder="4. On-time training delivered as scheduled"
              name=""
              id=""
              step={1}
              min={1}
              max={5}
              value={stuOnTimeTraining}
              onChange={(e) => setStuOnTimeTraining(e.target.value)}
            />
            <p className="title-ptag">
              5. Orion personnel's Behaviours: {stuPersonnelBehaviour}
            </p>
            <input
              className="input-name"
              type="range"
              placeholder="5. Orion personnel's Behaviour "
              name=""
              id=""
              step={1}
              min={1}
              max={5}
              value={stuPersonnelBehaviour}
              onChange={(e) => setStuPersonnelBehaviour(e.target.value)}
            />
            <input
              className="input-name"
              type="text"
              placeholder="Plz. specify your comments below in which area we need more attention."
              name=""
              id=""
              value={stuComment}
              onChange={(e) => setStuComment(e.target.value)}
            />
            <input
              className="input-name"
              type="text"
              placeholder="Signature"
              name=""
              id=""
              value={signature}
              onChange={(e) => setSignature(e.target.value)}
            />
            <div className="this-btn-cont" onClick={submitStudentFeedback}>
              <OrangeButtonLarge text={"Submit"} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentFeedback;
