import "./whatWeDo.css";
import { FocusAreaCard } from "../../../utils";

const WhatWeDo = ({data, title}) => {
  return (
    <>
      <div className="section-container">
        <div className="section-title-cont">
          <hr className="section-title-line" />
          <h3 className="section-title">{title}</h3>
        </div>

        <div className="card-holder">
          {data.map((item) => {
            return (
              <FocusAreaCard
                image={item.image}
                title={item.title}
                description={item.description}
                height="17rem"
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default WhatWeDo;
