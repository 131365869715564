export const key_sectors_titp = [
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1640599139/orion-new-website/titp/key_sector/Fishery_rscqgg.svg",
    name: "Fishery",
  },
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1640599204/orion-new-website/titp/key_sector/Construction_two_cclusm.svg",
    name: "Construction",
  },
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1640599260/orion-new-website/titp/key_sector/Textile_uniru0.svg",
    name: "Textile",
  },
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1640599312/orion-new-website/titp/key_sector/Machinery_y4t0jy.svg",
    name: "Machinery and Metals",
  },
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1640599366/orion-new-website/titp/key_sector/Food_Manufacturing_y8ecpp.svg",
    name: "Foof Manufacturing",
  },
  {
    link: "https://res.cloudinary.com/learnhat/image/upload/v1640599443/orion-new-website/titp/key_sector/Care_Worker_hur3q0.svg",
    name: "Care Worker",
  },
];

export const successStoriesData = [
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640594667/orion-new-website/Vid_1_utz7cg.png",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640594678/orion-new-website/Vid_2_gehnrg.png",
  },
];

export const photos_titp = [
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640599592/orion-new-website/titp/successfull_student/1_tzdtg4.jpg",
    name: "St 1",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640599668/orion-new-website/titp/successfull_student/2_jze2lg.jpg",
    name: "St 2",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640599754/orion-new-website/titp/successfull_student/3_ewybzw.jpg",
    name: "St 3",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640599817/orion-new-website/titp/successfull_student/4_ef3o3z.jpg",
    name: "St 4",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640599859/orion-new-website/titp/successfull_student/5_nnbrgk.jpg",
    name: "St 5",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640599964/orion-new-website/titp/successfull_student/7_n7t8lr.jpg",
    name: "St 6",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640600021/orion-new-website/titp/successfull_student/8_gr1dcb.jpg",
    name: "St 7",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640600090/orion-new-website/titp/successfull_student/9_xnixvg.jpg",
    name: "St 8",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640600193/orion-new-website/titp/successfull_student/10_xecohn.jpg",
    name: "St 9",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640600260/orion-new-website/titp/successfull_student/11_o42opf.jpg",
    name: "St 10",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640600694/orion-new-website/titp/successfull_student/13_apc9zr.jpg",
    name: "St 11",
  },
  {
    image:
      "https://res.cloudinary.com/learnhat/image/upload/v1640600744/orion-new-website/titp/successfull_student/14_lasn2r.jpg",
    name: "St 12",
  },
];

export const aboutTitpCard2 = [
  {
    title: "Benefits for Students",
    data: [
      "Complete Japanese Culture & Language Training",
      "On the Job Language & Skill Training",
      "Structurally Designed Modules",
      "170 hours of training by Expert Faculties and Trainers",
      "Placement Assistance",
      "Minimum Wage of 848 Yen/Hour (TITP)",
    ],
    title2: "Eligibility Criteria",
    data2: ["12th Standard"],
  },

  
];

export const aboutTitpCard1 = [
  {
    title: "Our Certification Program covers:",
    data: [
      "Basic Japanese Course",
      "Introduction to Japanese Culture and Lifestyle",
      "Japanese Style of Work",
      "Knowledge of Japanese Work Ethic",
      "Knowledge of Japanese Cuisine",
      "Fitness Related Guidance",
      "Practical Training",
    ],
  },
];
