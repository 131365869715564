import { OrangeButtonLarge } from "../../../utils/buttons/Buttons";
import "./ourSuccess.css";

const studtyAbroadData = [
  {
    number: "2,800+",
    name: "Students sent abroad",
  },
  {
    number: "29",
    name: "States of India represented",
  },
  {
    number: "24",
    name: "Countries with students admitted",
  },

  {
    number: "800+",
    name: "Universities partnered with",
  },

  {
    number: "10,900+",
    name: "Students IELTS trained",
  },
  {
    number: "54,000+",
    name: "Student community",
  },
];
const homeData = [
  {
    number: "4,00,000+",
    name: "Youth Skilled across India",
  },
  {
    number: "3,50,000+",
    name: "Provided Sustained Livelihoods",
  },
  {
    number: "100+",
    name: "Skilling Locations",
  },

  {
    number: "10,000+",
    name: "Staffing Associates",
  },

  {
    number: "3000+",
    name: "Corporate Tie-ups",
  },
  {
    number: "2800+",
    name: "Study Abroad Students",
  },
];

const OurSuccess = ({ studyabroad }) => {
  const data = studyabroad ? studtyAbroadData : homeData;
  return (
    <div
      className={
        studyabroad
          ? "section-container bg-gray-lite"
          : "section-container bg-apna-blue"
      }
    >
      <div className="section-title-cont">
        <hr className="section-title-line" />
        <h3
          className={studyabroad ? "section-title" : "section-title cl-white"}
        >
          Our Success
        </h3>
      </div>
      <div className="card-holder our-success-card-holder">
        {data.map((item) => {
          return (
            <div
              className="our-success-numbers-card"
              style={{ color: studyabroad ? "#FFAA46" : "white" }}
            >
              <h4 className="our-s-number">{item.number}</h4>
              <p className="our-s-name">{item.name}</p>
            </div>
          );
        })}
      </div>
      {studyabroad && (
        <div className="kno-more-btnn-cont">
          <a href="#9">
            <OrangeButtonLarge text={"Book FREE Session"} />
          </a>
        </div>
      )}
    </div>
  );
};

export default OurSuccess;
